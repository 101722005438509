<template>
  <div class="pa-5">
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h2>Gestion des matériaux</h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Materials/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter un matériau</v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-center'>Nom du matériau</th>
            <th class='text-center'>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='material in materials' :key='material.id'>
            <td>{{ material.name }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Materials/' + material.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="black"
                      icon
                      @click='copyMaterial(material)'
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Dupliquer</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteMaterial(material)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'materials',
    data () {
      return {
        materials: []
      }
    },
    mounted () {
      this.getMaterials()
    },
    methods: {
      deleteMaterial (material) {
        this.$store.dispatch('deleteMaterial', material.id).then(() => {
          this.materials = this.$store.getters['GET_MATERIALS']()
          this.products = this.$store.getters['GET_PRODUCTS']()
          var found = false
          this.products.forEach((product) => {
            if (product.textures && product.textures.includes(material.id)) {
              found = true
              return -1
            }
          })
          if (found) {
            this.$emit('notify', {
              color: 'red',
              text: 'Un ou plusieurs produits ne possèdent désormais plus de matériaux !'
            })
          } else {
            this.$emit('notify', {
              color: 'green',
              text: 'Matériau supprimé'
            })
          }
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      getMaterials () {
        this.$store.dispatch('getAllMaterials').then(() => {
          this.materials = this.$store.getters['GET_MATERIALS']()
        })
      },
      modifyMaterial (material) {
        this.$store.dispatch('editMaterial', material).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      copyMaterial (material) {
        var newMaterial = JSON.parse(JSON.stringify(material))
        delete newMaterial.id
        newMaterial.name = newMaterial.name + ' (Copie)'
        this.$store.dispatch('addMaterial', newMaterial).then(() => {
          this.getMaterials()
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
