import { render, staticRenderFns } from "./SettingsText.vue?vue&type=template&id=bfbab20c&scoped=true&"
import script from "./SettingsText.vue?vue&type=script&lang=js&"
export * from "./SettingsText.vue?vue&type=script&lang=js&"
import style0 from "./SettingsText.vue?vue&type=style&index=0&id=bfbab20c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfbab20c",
  null
  
)

export default component.exports