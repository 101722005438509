<template>
  <div>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        v-if="currentRouteName !== 'ThreeScene' && drawerLeft"
    >
      <v-row class="grey lighten-4">
        <v-col class="pa-10">
          <v-img src="../assets/LogoWobz.svg"></v-img>
        </v-col>
      </v-row>
      <v-list shaped>
        <div v-for="item in items" :key="item.text" @click="selectItem(item)">
          <v-list-item
              :key="item.text"
              link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="$store.getters['GET_IS_AUTHENTICATED']() && $store.getters['GET_ROLES']().find((role) => { return role === 'ROLE_ADMIN' })">
          <v-divider inset></v-divider>
          <v-list-item
              @click="selectItem({ path: '/Accounts' })"
              link
          >
            <v-list-item-action>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Gestion utilisateurs
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        :clipped-right="$vuetify.breakpoint.lgAndUp"
        dark
        app
        prominent
        src="https://dalvin.eu/pub/media/themelem/back_high.jpg"
    >
      <v-toolbar-title>
        <div v-if="currentRouteName !== 'ThreeScene'" class="hidden-sm-and-down">Administration configurateur 3D</div>
        <div v-else>
          <v-btn light @click="$router.push({ name: 'Products' })">Retour</v-btn>
          <v-btn light @click="eventBus.$emit('sauvegarder')" class="ma-2">Sauvegarder</v-btn>
          <v-btn light @click="eventBus.$emit('sauvegarderquitter')">Sauv. et quitter</v-btn>
          <v-checkbox v-model="checked" @click="eventBus.$emit('toggleReperes')" label="Afficher repères"></v-checkbox>
        </div>
      </v-toolbar-title>
      <v-spacer>
      </v-spacer>
      <div v-if="$store.getters['GET_IS_AUTHENTICATED']()">
        <h4>Session : {{ $store.getters['GET_USERNAME']() }}</h4>
        <v-btn light @click="logout">Déconnexion</v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <router-view @notify="notify"/>
    </v-main>
    <v-snackbar
        v-model="snackbar"
        :timeout="5000"
        :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EventBus from '../bus.js'

export default {
  name: 'HomeLayout',
  data () {
    return {
      eventBus: EventBus,
      snackbar: false,
      textSnackbar: '',
      colorSnackbar: 'blue',
      drawerLeft: true,
      checked: false,
      loading: false,
      items: [
        { icon: 'mdi-web', text: 'Sites', path: '/Sites' },
        { icon: 'mdi-cup', text: 'Produits', path: '/Products' },
        { icon: 'mdi-altimeter', text: 'Graduations', path: '/Measures' },
        { icon: 'mdi-texture', text: 'Matériaux', path: '/Materials' },
        { icon: 'mdi-shape', text: 'Catégories', path: '/Categories' },
        { icon: 'mdi-checkerboard', text: 'Motifs', path: '/Decos' },
        { icon: 'mdi-view-dashboard', text: 'Rubriques', path: '/Sections' },
        { icon: 'mdi-format-font', text: 'Textes', path: '/Texts' },
        { icon: 'mdi-image-edit', text: 'Thèmes', path: '/Themes' }
      ]
    }
  },
  created () {
    EventBus.$on('showLeftNav', () => {
      this.drawerLeft = true
    })
    EventBus.$on('hideLeftNav', () => {
      this.drawerLeft = false
    })
    EventBus.$on('reperesOff', () => {
      this.checked = false
    })
    if (this.$route.path === '/') {
      this.$router.push({ name: 'Sites' })
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['GET_IS_AUTHENTICATED']()
    },
    currentRouteName () {
      return this.$route.path.split('/')[1]
    }
  },
  methods: {
    logout () {
      this.loading = true
      this.$store.dispatch('logout').then(() => {
        this.drawerLeft = !this.drawerLeft
        this.loading = false
        this.$store.commit('SET_IS_AUTHENTICATED', false)
        this.$store.commit('SET_ROLES', [])
        this.$store.commit('SET_USERNAME', '')
        this.$router.push({ name: 'Login' })
      })
    },
    selectItem (item) {
      if (item.path && this.$route.path !== item.path) {
        this.$router.push(item.path)
      }
    },
    notify (info) {
      this.textSnackbar = info.text
      this.colorSnackbar = info.color
      this.snackbar = true
    }
  }
}
</script>

<style scoped>

</style>
