import { render, staticRenderFns } from "./ThreeScene.vue?vue&type=template&id=5fff5856&"
import script from "./ThreeScene.vue?vue&type=script&lang=js&"
export * from "./ThreeScene.vue?vue&type=script&lang=js&"
import style0 from "./ThreeScene.vue?vue&type=style&index=0&id=5fff5856&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports