export default function () {
  return {
    sites: [],
    currentSite: {},
    currentSection: {},
    currentCategory: {},
    currentProduct: {},
    currentTag: {},
    currentDeco: {},
    currentDecos: {},
    currentTheme: {},
    currentThemes: {},
    currentText: {},
    currentMeasure: {},
    currentAccount: {},
    currentMaterial: {},
    sections: [],
    accounts: [],
    themes: [],
    categories: [],
    measures: [],
    materials: [],
    tags: [],
    texts: [],
    products: [],
    decos: [],
    allObjects: [],
    selectedFonts: [],
    areFontsLoaded: false,
    isAuthenticated: false,
    roles: [],
    username: '',
    lastSelectedTheme: null,
    logo: {},
    logoMobile: {}
  }
}
