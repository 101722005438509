<template>
  <div>
    <v-overlay v-if="fontsLoading">
      <v-progress-circular indeterminate size="120">Chargement des polices</v-progress-circular>
    </v-overlay>
    <v-list
        dense
        nav
        class="py-0"
    >
      <v-list-item>
        <v-textarea label="Texte" type="text" v-model="text" @input="updateObj()" rows="1"></v-textarea>
      </v-list-item>
      <v-list-item>
        <v-row>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs}">
              <v-btn color="primary"
                     dark
                     v-bind="attrs"
                     v-on="on"
                     icon
              >
                <v-icon>mdi-format-align-{{ textAlign }}</v-icon>
              </v-btn>
            </template>
            <v-list style="display:flex">
              <v-list-item @click="changeAlign('left')">
                <v-icon>mdi-format-align-left</v-icon>
              </v-list-item>
              <v-list-item @click="changeAlign('center')">
                <v-icon>mdi-format-align-center</v-icon>
              </v-list-item>
              <v-list-item @click="changeAlign('right')">
                <v-icon>mdi-format-align-right</v-icon>
              </v-list-item>
              <v-list-item @click="changeAlign('justify')">
                <v-icon>mdi-format-align-justify</v-icon>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn @click="changeBold()"
                 :color="bold ? 'primary' : 'secondary'"
                 icon
                 :outlined="bold"
          >
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
          <v-btn @click="changeItalic()"
                 :color="italic ? 'primary' : 'secondary'"
                 icon
                 :outlined="italic"
          >
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
          <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary"
                     dark
                     v-bind="attrs"
                     v-on="on"
                     icon
              >
                <v-icon>mdi-format-text-rotation-vertical</v-icon>
              </v-btn>
            </template>
            <v-slider v-model="lineHeight"
                      max="3" step="0.05" min="0.1"
            ></v-slider>
          </v-menu>
          <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary"
                     dark
                     v-bind="attrs"
                     v-on="on"
                     icon
              >
                <v-icon>mdi-format-text-rotation-none</v-icon>
              </v-btn>
            </template>
            <v-slider v-model="charSpacing"
                      max="1000" step="10" min="-200"
            ></v-slider>
          </v-menu>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs}">
              <v-btn color="primary"
                     dark
                     v-bind="attrs"
                     v-on="on"
                     icon
              >
                <v-icon>mdi-format-font</v-icon>
              </v-btn>
            </template>
            <v-slider v-model="strokeWidth"
                      @input="updateObj"
                      max="3" step="0.05" min="0"
                      label="Epaisseur"
            ></v-slider>
            <v-color-picker
                class="ma-2"
                :swatches="swatches"
                show-swatches
                v-model="colorPickerStroke"
                @input="changeStrokeColor(colorPickerStroke)"
            ></v-color-picker>
          </v-menu>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs}">
              <v-btn color="primary"
                     dark
                     v-bind="attrs"
                     v-on="on"
                     icon
              >
                <v-icon>mdi-palette</v-icon>
              </v-btn>
            </template>
            <v-color-picker
                class="ma-2"
                :swatches="swatches"
                show-swatches
                v-model="colorPickerFill"
                @input="changeFillColor(colorPickerFill)"
            ></v-color-picker>
          </v-menu>
        </v-row>
      </v-list-item>
      <v-list-item>
        <v-row>
          <v-col
              cols="12"
              sm="9"
          >

            <!--                  <v-menu-->
            <!--                    :close-on-content-click="false"-->
            <!--                  >-->
            <!--                    <template v-slot:activator="{ on, attrs }">-->
            <!--                      <v-text-field-->
            <!--                        v-on="on"-->
            <!--                        v-bind="attrs"-->
            <!--                        v-model="fontSelected"-->
            <!--                        @keydown="(event) => { event.preventDefault() }"-->
            <!--                        >-->
            <!--                      </v-text-field>-->
            <!--                    </template>-->
            <!--                    <v-list>-->
            <!--                      <v-list-item>-->
            <!--                        <v-list-item-content>-->
            <!--                          <v-text-field-->
            <!--                            v-model="fontSearch"-->
            <!--                            placeholder="Recherche..."-->
            <!--                            >-->
            <!--                          </v-text-field>-->
            <!--                        </v-list-item-content>-->
            <!--                      </v-list-item>-->
            <!--                      <div style="overflow-y: auto; max-height: 30vh; max-width: 20vw; overflow-x: hidden">-->
            <!--                        <v-list-item-->
            <!--                            class="font-option"-->
            <!--                            v-for="font in fontsFiltered"-->
            <!--                            :key="font.name"-->
            <!--                            @mouseenter="changeFontHover(font.name)"-->
            <!--                            @mouseleave="rollbackFont"-->
            <!--                            :style="font.name === fontSelected ? 'background-color: #C7BECB' : ''"-->
            <!--                            @click="changeFont(font.name)"-->
            <!--                        >-->
            <!--                          <v-list-item-title><span :style="'font-family: ' + font.name + ' !important'">{{ text }}</span><br/>{{ font.name }}</v-list-item-title>-->
            <!--                        </v-list-item>-->
            <!--                      </div>-->
            <!--                    </v-list>-->
            <!--                  </v-menu>-->
            <v-autocomplete
                v-model="fontSelected"
                :items="fonts"
                item-text="name"
                item-value="name"
                cache-items
                @change="changeFont(fontSelected)"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content @mouseenter="changeFontHover(data.item.name)" @mouseleave="rollbackFont">
                    <v-list-item-title :class="data.item.name" :style="'font-family: ' + data.item.name"
                                       v-html="text"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.name"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
              cols="10" sm="3">
            <v-text-field v-model="fontSize"
                          type="number"
                          min="1"
                          label="Taille"
                          @input="updateObj"/>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item>
        <v-slider
            v-model="opacity"
            label="Opacité"
            @input="updateObj"
            step="0.01"
            min="0.01"
            max="1"
        >
        </v-slider>
      </v-list-item>
      <mask-order-controls :fcanvas="fcanvas" :obj="obj"></mask-order-controls>
      <v-list-item v-if="creator.indexOf('theme') !== -1">
        <v-checkbox v-model="obj.modifiable" label="Modifiable"></v-checkbox>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import MaskOrderControls from '@/components/drawerRight/MaskOrderControls'

export default {
  props: [
    'obj',
    'fcanvas',
    'refreshSetting',
    'fonts',
    'fontsLoading',
    'creator'
  ],
  components: { MaskOrderControls },
  name: 'SettingsText',
  data () {
    return {
      originalFont: this.obj.originalFont ? this.obj.originalFont : this.obj.fontFamily,
      fontSelected: this.obj.fontFamily,
      colorPickerStroke: null,
      colorPickerFill: null,
      stroke: this.obj.stroke,
      diameter: this.obj.diameter,
      strokeWidth: this.obj.strokeWidth,
      fill: this.obj.fill,
      fontFamily: this.obj.fontFamily,
      fontSize: this.obj.fontSize,
      charSpacing: this.obj.charSpacing,
      bold: this.obj.fontWeight === 'bold',
      italic: this.obj.fontStyle === 'italic',
      underline: this.obj.underline,
      textAlign: this.obj.textAlign,
      left: this.obj.left,
      originX: this.obj.originX,
      top: this.obj.top,
      angle: this.obj.angle,
      text: this.obj.text,
      lineHeight: this.obj.lineHeight,
      opacity: this.obj.opacity,
      hover: false,
      fontSearch: '',
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055']
      ]
    }
  },
  created () {
    this.obj.on({
      'object:modified': (e) => {
        this.select(e)
      }
    })
    this.stroke = typeof this.obj.stroke === 'object' ? 'rgb(0,0,0)' : this.obj.stroke
    this.obj.set({ stroke: this.stroke })
    if (this.obj.stroke.indexOf('(') !== -1) {
      this.colorPickerStroke = this.rgbToHex(this.obj.stroke)
    } else {
      this.colorPickerStroke = this.obj.stroke
    }
    if (this.obj.fill.indexOf('(') !== -1) {
      this.colorPickerFill = this.rgbToHex(this.obj.fill)
    } else {
      this.colorPickerFill = this.obj.fill
    }
  },
  computed: {
    fontsFiltered () {
      return this.fonts.filter((font) => {
        return this.fontSearch ? font.name.toLowerCase().indexOf(this.fontSearch.toLowerCase()) !== -1 : true
      })
    }
  },
  watch: {
    lineHeight: function () {
      this.updateObj()
    },
    charSpacing: function () {
      this.updateObj()
    },
    refreshSetting () {
      this.updateLocalObj()
    },
    obj () {
      this.updateLocalObj()
    }
  },
  methods: {
    rgbToHex (rgb) {
      var rgbvalues = rgb.split('(')[1].split(')')[0].split(',')
      var hexvalues = rgbvalues.map(function (x) {
        x = parseInt(x).toString(16)
        return (x.length === 1 ? '0' + x : x)
      })
      return '#' + hexvalues.join('')
    },
    changeAlign (align) {
      this.textAlign = align
      this.originX = align
      this.updateObj()
    },
    changeBold () {
      this.bold = !this.bold
      this.updateObj()
    },
    changeItalic () {
      this.italic = !this.italic
      this.updateObj()
    },
    changeStrokeColor (color) {
      this.stroke = color
      this.updateObj()
    },
    changeFillColor (color) {
      this.fill = color
      this.updateObj()
    },
    updateLocalObj () {
      if (this.obj.stroke && this.obj.stroke.indexOf('(') !== -1) {
        this.colorPickerStroke = this.rgbToHex(this.obj.stroke)
      } else {
        this.colorPickerStroke = this.obj.stroke
      }
      if (this.obj.fill.indexOf('(') !== -1) {
        this.colorPickerFill = this.rgbToHex(this.obj.fill)
      } else {
        this.colorPickerFill = this.obj.fill
      }
      this.stroke = typeof this.obj.stroke === 'object' ? 'rgb(0,0,0)' : this.obj.stroke
      this.originalFont = this.obj.originalFont ? this.obj.originalFont : this.obj.fontFamily
      this.fontSelected = this.obj.fontFamily
      this.fontFamily = this.obj.fontFamily
      this.fontSize = this.obj.fontSize
      this.strokeWidth = this.obj.strokeWidth
      this.diameter = this.obj.diameter
      this.fill = this.obj.fill
      this.bold = this.obj.fontWeight === 'bold'
      this.italic = this.obj.fontStyle === 'italic'
      this.underline = this.obj.underline
      this.textAlign = this.obj.textAlign
      this.left = this.obj.left
      this.originX = this.obj.originX
      this.lineHeight = this.obj.lineHeight
      this.top = this.obj.top
      this.charSpacing = this.obj.charSpacing
      this.angle = this.obj.angle
      this.text = this.obj.text
      this.opacity = this.obj.opacity
    },
    updateObj () {
      this.obj.set({
        originalFont: this.originalFont,
        stroke: this.stroke,
        strokeWidth: this.strokeWidth,
        fill: this.fill,
        diameter: this.diameter,
        fontFamily: this.fontFamily,
        fontSize: this.fontSize,
        fontWeight: this.bold ? 'bold' : 'normal',
        fontStyle: this.italic ? 'italic' : 'normal',
        underline: this.underline,
        textAlign: this.textAlign,
        lineHeight: this.lineHeight,
        left: this.left,
        originX: this.originX,
        top: this.top,
        angle: this.angle,
        text: this.text,
        charSpacing: this.charSpacing,
        opacity: this.opacity
      })
      this.$emit('renderAll')
    },
    changeFont (font) {
      if (font) {
        this.hover = false
        this.fontFamily = font
        this.originalFont = font
        this.fontSelected = font
        this.updateObj()
      }
    },
    changeFontHover (font) {
      this.hover = true
      this.fontFamily = font
      this.updateObj()
    },
    rollbackFont () {
      if (this.hover === true) {
        this.fontFamily = this.originalFont
        this.updateObj()
      }
    }
  }
}
</script>

<style scoped>
.font-option:hover {
  background-color: #dbdbdb;
}
</style>
