<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2>Gestion des produits</h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Products/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter un produit</v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-center'>Statut de publication</th>
            <th class='text-center'>Nom du produit</th>
            <th class='text-center'>SKU
              <v-icon @click="sortProducts('sku', 'asc')">mdi-arrow-up</v-icon>
              <v-icon @click="sortProducts('sku', 'desc')">mdi-arrow-down</v-icon>
            </th>
            <th class='text-center'>Catégorie</th>
            <th class='text-center'>Actions</th>
            <th class='text-center'>Tri</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='product in products' :key='product.id'>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyProduct(product)"
                    v-model="product.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ product.name }}</td>
            <td>{{ product.sku }}</td>
            <td>{{ getCategorieLabel(product.categoryId)}}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="black"
                      icon
                      @click='configProduct(product)'
                  >
                    <v-icon>mdi-cube-outline</v-icon>
                  </v-btn>
                </template>
                <span>Configuration 3D</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Products/' + product.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="black"
                      icon
                      @click='copyProduct(product)'
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Dupliquer</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteProduct(product)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </td>
            <td>
              <div style="display: inline-flex; flex-direction: column">
                <v-tooltip top v-if="products.indexOf(product) !== 0">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="red"
                        icon
                        @click='increasePrio(product)'
                    >
                      <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Monter</span>
                </v-tooltip>
                <v-tooltip bottom v-if="products.indexOf(product) !== products.length - 1">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="red"
                        icon
                        @click='decreasePrio(product)'
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Descendre</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    </div>
</template>

<script>
  export default {
    name: 'Products',
    data () {
      return {
        products: [],
        categories: [],
        sortBy: 'asc'
      }
    },
    created () {
      this.getProducts()
      this.getCategories()
    },
    methods: {
      algo () {
        this.products.forEach((p) => {
          var matSku = p.sku.substring(8, 10)
          p.sku = p.sku.substring(0, 8)
          p.textures = [{ sku: matSku, textures: JSON.parse(JSON.stringify(p.textures)), otherColors: [], otherEmissivesColors: [] }]
          p.textures.forEach((mat) => {
            mat['colorList'] = JSON.parse(JSON.stringify(p.colorList))
          })
          console.log(JSON.parse(JSON.stringify(p)))
          // this.$store.dispatch('editProduct', p)
        })
      },
      increasePrio (product) {
        // var oldIndex = product.index
        // product.index = this.products[this.products.indexOf(product) - 1].index
        // this.products[this.products.indexOf(product) - 1].index = oldIndex
        // this.updateProduct(product)
        // this.updateProduct(this.products[this.products.indexOf(product) - 1])
        // this.sortProducts()
      },
      decreasePrio (product) {
        // var oldIndex = product.index
        // product.index = this.products[this.products.indexOf(product) + 1].index
        // this.products[this.products.indexOf(product) + 1].index = oldIndex
        // this.updateProduct(product)
        // this.updateProduct(this.products[this.products.indexOf(product) + 1])
        // this.sortProducts()
      },
      sortProducts (field, direction) {
        this.products.sort((a, b) => {
          if (a[field] < b[field]) {
            return direction === 'asc' ? -1 : 1
          }
          if (a[field] > b[field]) {
            return direction === 'asc' ? 1 : -1
          }
          return 0
        })
        // this.products.forEach((p, index) => {
        //   if (p.index !== index) {
        //     p.index = index
        //     this.updateProduct(p)
        //   } else {
        //     p.index = index
        //   }
        // })
      },
      updateProduct (product) {
        this.$store.dispatch('editProduct', product)
      },
      configProduct (product) {
        this.$router.push('/ThreeScene/' + product.id)
      },
      copyProduct (product) {
        var newProduct = JSON.parse(JSON.stringify(product))
        delete newProduct.id
        newProduct.name = newProduct.name + ' (Copie)'
        // newProduct.textures = []
        this.$store.dispatch('addProduct', newProduct).then(() => {
          this.getProducts()
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      deleteProduct (product) {
        this.$store.dispatch('deleteProduct', product.id).then(() => {
          this.products = this.$store.getters['GET_PRODUCTS']()
          this.sortProducts()
          this.$emit('notify', {
            color: 'green',
            text: 'Produit supprimé'
          })
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      getProducts () {
        this.$store.dispatch('getAllProducts').then(() => {
          this.products = this.$store.getters['GET_PRODUCTS']()
          this.sortProducts('index', 'asc')
        })
      },
      getCategories () {
        this.$store.dispatch('getAllCategories').then(() => {
          this.categories = this.$store.getters['GET_CATEGORIES']()
        })
      },
      getCategorieLabel (id) {
        var categorie = this.categories.find((category) => {
          return category.id === id
        })
        if (categorie) {
          return categorie.name
        } else {
          return ''
        }
      },
      modifyProduct (product) {
        this.$store.dispatch('editProduct', product).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
