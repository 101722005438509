<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2>Edition d'un thème</h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' @click="$router.push({ name: 'Themes' })">Retour</v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row>
            <v-col
            >
              <v-text-field
                  v-model="currentTheme.name"
                  label="Nom du thème"
                  required
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
            >
              <v-text-field
                  v-model="currentTheme.sku"
                  label="Référence du thème (exemple : MAR001)"
                  required
                  maxlength="6"
                  outlined
                  @input="capsSku"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
            >
              <v-text-field
                  v-model="currentTheme.author"
                  label="Auteur du thème"
                  required
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                  id="sectionsForm"
                  v-model="currentTheme.sectionList"
                  :items="sections"
                  item-text="name"
                  item-value="id"
                  label="Rubriques"
                  multiple
                  outlined
                  clearable
                  required></v-select>
            </v-col>
          </v-row>
          <v-card outlined>
            <v-card-title>Liste des traductions</v-card-title>
            <v-card-text>
              <v-row class="align-center" v-for="lang in currentTheme.labelList" :key="lang.langValue">
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-chip>
                    {{
                      languageList.find((l) => {
                        return l.value === lang.langValue
                      }).country
                    }}
                  </v-chip>
                </v-col>
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-text-field
                      v-model="lang.label"
                      required
                      outlined
                      clearable
                      :label="'Nom du thème en ' + languageList.find((l) => { return l.value === lang.langValue }).country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined class="mt-10">
            <v-card-title>Liste des tags de recherche</v-card-title>
            <v-card-text>
              <v-row style="align-items: baseline">
                <v-col>
                  <v-select
                      id="tagsForm"
                      v-model="currentTheme.tagList"
                      :items="tags"
                      item-text="name"
                      item-value="id"
                      label="Mots-clés"
                      multiple
                      required
                      outlined
                  ></v-select>
                </v-col>
                <v-col>
                  <div class='my-2 text-left pa-2'>
                    <v-btn @click='addTagForm'>{{ newTag ? 'Annuler' : 'Créer un tag' }}</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="newTag">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="tag.name"
                          label="Nom du tag"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-for="lang in tag.labelList" :key="lang.langValue">
                    <v-col>
                      <v-chip>
                        {{
                          languageList.find((l) => {
                            return l.value === lang.langValue
                          }).country
                        }}
                      </v-chip>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="lang.label"
                          required
                          outlined
                          :label="'Nom du thème en ' + languageList.find((l) => { return l.value === lang.langValue }).country"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class='my-2 text-right pa-2'>
                        <v-btn @click='addTag'>Ajouter le tag</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentTheme.colorsEditable"
            label="Couleurs modifiables"
        ></v-switch><v-switch
            style="margin-right: 30px"
            v-model="currentTheme.published"
            label="Publication"
        ></v-switch>
        <v-btn color='primary' @click='addOrModifyTheme(false)'>Terminer</v-btn>
        <v-btn style="margin-left: 5px" color='primary' @click='addOrModifyTheme(true)'>Continuer</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ThemesForm',
  data () {
    return {
      valid: false,
      currentTheme: {
        id: '',
        sku: '',
        name: '',
        labelList: [],
        published: false,
        colorsEditable: true,
        tagList: [],
        sectionList: [],
        author: ''
      },
      tags: [],
      newTag: false,
      tag: {
        name: '',
        labelList: []
      },
      themes: [],
      sections: [],
      languageList: []
    }
  },
  created () {
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentTheme()
    } else {
      this.getSites()
    }
  },
  methods: {
    capsSku () {
      this.currentTheme.sku = this.currentTheme.sku.toUpperCase()
    },
    getSections () {
      this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
      })
    },
    getSites () {
      this.getSections()
      this.$store.dispatch('getAllSites').then(() => {
        this.sites = this.$store.getters['GET_SITES']()
        this.languageList = []
        this.sites.forEach((site) => {
          site.languageList.forEach((langToAdd) => {
            if (!this.languageList.find((lang) => {
              return lang.value === langToAdd.value
            })) {
              this.languageList.push(langToAdd)
            }
          })
        })
        this.languageList.forEach((lang) => {
          if (!this.currentTheme.labelList.find((langLabel) => {
            return langLabel.langValue === lang.value
          })) {
            this.currentTheme.labelList.push({ langValue: lang.value, label: '' })
          }
        })
        this.getTags()
      })
    },
    addTagForm () {
      if (this.newTag) {
        this.newTag = false
      } else {
        this.tag.name = ''
        this.tag.labelList = []
        this.newTag = true
        this.languageList.forEach((lang) => {
          this.tag.labelList.push({ langValue: lang.value, label: '' })
        })
      }
    },
    addTag () {
      this.newTag = false
      this.$store.dispatch('addTag', this.tag).then(() => {
        var addedTag = this.$store.getters['GET_CURRENT_TAG']()
        this.tags.push(addedTag)
        this.currentTheme.tagList.push(addedTag.id)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    getTags () {
      this.$store.dispatch('getAllTags').then(() => {
        this.tags = this.$store.getters['GET_TAGS']()
      })
    },
    getCurrentTheme () {
      this.$store.dispatch('getTheme', this.$route.params.id).then(() => {
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        this.$store.commit('SET_LAST_SELECTED_THEME', this.currentTheme.id ? this.currentTheme.id : null)
        if (this.currentTheme === null) {
          this.$emit('notify', {
            color: 'red',
            text: 'Thème introuvable'
          })
          this.$router.push({ name: 'Themes' })
        }
        this.getSites()
      })
    },
    addOrModifyTheme (continuer) {
      this.$refs.form.validate()
      this.isSkuAvailable().then((res) => {
        if (res) {
          if (this.valid) {
            if (this.currentTheme.id) {
              this.modifyTheme(continuer)
            } else {
              this.addTheme(continuer)
            }
          }
        } else {
          this.$emit('notify', {
            color: 'red',
            text: 'Ce SKU est déjà utilisé'
          })
        }
      })
    },
    addTheme (continuer) {
      this.$store.dispatch('addTheme', this.currentTheme).then(() => {
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        continuer ? this.$router.push('/ThemeCreator/' + this.currentTheme.id) : this.$router.push('/Themes')
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    modifyTheme (continuer) {
      this.$store.dispatch('editTheme', this.currentTheme).then(() => {
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        continuer ? this.$router.push('/ThemeCreator/' + this.currentTheme.id) : this.$router.push('/Themes')
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    async isSkuAvailable () {
      await this.$store.dispatch('getAllThemes')
      var themes = this.$store.getters['GET_THEMES']()
      var result = true
      themes.forEach((theme) => {
        if (theme.id !== this.currentTheme.id && theme.sku.toLowerCase() === this.currentTheme.sku.toLowerCase()) {
          result = false
        }
      })
      return result
    }
  }
}
</script>

<style scoped>

</style>
