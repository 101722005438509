<template>
  <div style="background-color: #555; height: 83vh" @mousedown="outsideClick">
    <v-overlay v-if="themeLoading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <canvas id="canvas"/>
    <v-navigation-drawer
        right
        v-model="drawerRight"
        width="350px"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        class="pa-4"
    >
    <drawer-right-theme
        @addBackPattern="addBackPattern"
        @add="addObj"
        :objSelected="objSelected"
        :fontsLoading="fontsLoading"
        :refreshSetting="refreshSetting"
        @remove="removeObj"
        @renderAll="renderAll"
        :fonts="fonts"
        :fcanvas="fcanvas"
        @showGuidelines="showGuidelinesChanged"
        :showGuidelines="showGuidelines"
      >
    </drawer-right-theme>
    </v-navigation-drawer>
    <v-dialog v-model="importSvgModal" max-width="500">
      <v-card>
        <v-card-title class="headline">Importer un svg</v-card-title>
        <v-file-input v-model="inputFileSvg" accept="image/svg+xml" label="Fichier selectionné"
                      style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importSvgModal = false"> Annuler</v-btn>
          <v-btn color="primary' darken-1" text @click="addSvg"> Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importImageModal" max-width="500">
      <v-card>
        <v-card-title class="headline">Importer une image</v-card-title>
        <v-file-input v-model="inputFileImage" accept="image/*" label="Fichier selectionné"
                      style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importImageModal = false"> Annuler</v-btn>
          <v-btn color="primary' darken-1" text @click="addImage"> Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <svg id="SVG-ElementID"></svg>
    <v-footer fixed class="font-weight-medium" style="height: 6vh">
      <v-col class="text-center" cols="12">
        <v-btn @click="saveCanvas">
          Sauvegarder le thème
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import DrawerRightTheme from '../components/drawerRight/DrawerRightTheme'
import WebFont from 'webfontloader'

export default {
  name: 'ThemeCreator',
  components: {
    DrawerRightTheme
  },
  data () {
    return {
      fcanvas: null,
      objSelected: null,
      drawerRight: true,
      currentTheme: {},
      importSvgModal: false,
      inputFileSvg: null,
      idDeco: '',
      importImageModal: false,
      refreshSetting: 0,
      inputFileImage: null,
      themeLoading: true,
      fontsLoading: true,
      fontsUsed: [],
      fonts: [
        { id: 0, name: 'Arial' },
        { id: 1, name: 'Arial Black' },
        { id: 2, name: 'Bookman Old Style' },
        { id: 3, name: 'Comic Sans MS' },
        { id: 4, name: 'Courier' },
        { id: 5, name: 'Courier New' },
        { id: 6, name: 'Garamond' },
        { id: 7, name: 'Georgia' },
        { id: 8, name: 'Impact' },
        { id: 9, name: 'Lucida Console' },
        { id: 10, name: 'Lucida Sans Unicode' },
        { id: 11, name: 'MS Sans Serif' },
        { id: 12, name: 'MS Serif' },
        { id: 13, name: 'Palatino Linotype' },
        { id: 14, name: 'Symbol' },
        { id: 15, name: 'Tahoma' },
        { id: 16, name: 'Times New Roman' },
        { id: 17, name: 'Trebuchet MS' },
        { id: 18, name: 'Verdana' }],
        showGuidelines: true
    }
  },
  mounted () {
    this.$store.dispatch('clearSelectedFonts')
    this.initCanvas()
    this.fcanvas.on({
      'selection:created': (e) => {
        if (!this.hover) {
          this.select(e)
        }
      },
      'selection:cleared': () => {
        this.objSelected = null
      },
      'selection:updated': (e) => {
        if (!this.hover) {
          this.objSelected = null
          this.select(e)
        }
      },
      'object:modified': () => {
        this.refreshSetting += 1
      }
    })
    this.$store.dispatch('getTheme', this.$route.params.id).then(() => {
      this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
      if (!this.currentTheme) {
        this.$router.push({ name: 'Themes' })
      }
      var parsedJson = JSON.parse(this.currentTheme.themeJson)
      if (parsedJson) {
        if (parsedJson.fonts.length > 0) {
          parsedJson.fonts.forEach((font) => {
            this.fontsUsed.push({ id: this.fontsUsed.length, name: font.split('family=')[1], googleFont: true })
          })
          WebFont.load({
            google: {
              families: this.fontsUsed.map(font => font.name)
            },
            active: () => {
              this.loadThemeJSON()
            }
          })
        } else {
          this.loadThemeJSON()
        }
      } else {
        this.themeLoading = false
      }
      this.$axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDJC2o9SopWiTzGrDbYaPZagP9gmxwEHgw').then(async (res) => {
        res.data.items.forEach((i) => {
          this.fonts.push({ id: this.fonts.length, name: i.family, googleFont: true })
        })
        this.fonts.sort((a, b) => {
          return b.name < a.name ? 1 : -1
        })
        if (!this.$store.state.areFontsLoaded) {
          const fontsCopy = [...this.fonts]
          while (fontsCopy.length) {
            const current = fontsCopy.splice(0, 100)
              WebFont.load({
                google: {
                  families: current.filter((font) => {
                    return font.googleFont
                  }).map(font => font.name)
                },
                active: () => {
                  this.fontsLoading = false
                  this.$store.state.areFontsLoaded = true
                }
              })
            }
        } else {
          this.fontsLoading = false
        }
      })
    })
  },
  methods: {
    loadThemeJSON () {
      this.fcanvas.loadFromJSON(this.currentTheme.themeJson, () => {
        var boundingRect = this.fcanvas.getObjects().find((obj) => { return obj.name === 'boundingRect' })
        this.fcanvas.remove(boundingRect)
        fabric.util.clearFabricFontCache()

        var scale = this.fcanvas.getHeight() / 256
        var width = scale * 256
        this.fcanvas.setDimensions({
          width: width,
          height: width
        })
        this.fcanvas.calcOffset()
        var objects = this.fcanvas.getObjects()
        for (var i in objects) {
          var scaleX = objects[i].scaleX
          var scaleY = objects[i].scaleY
          var left = objects[i].left
          var top = objects[i].top

          objects[i].scaleX = scaleX * scale
          objects[i].scaleY = scaleY * scale
          objects[i].left = left * scale
          objects[i].top = top * scale
          objects[i].setCoords()

          this.fcanvas.renderAll()
        }

        this.fcanvas.getObjects().forEach((objMask) => {
          if (objMask.isMask && objMask.customClipPathId) {
            this.fcanvas.getObjects().forEach((objToClip) => {
              if (!objToClip.isMask && objToClip.customClipPathId === objMask.customClipPathId) {
                objToClip.clipPath = objMask
                this.fcanvas.renderAll()
              }
            })
          }
        })

        if (this.currentTheme.idDeco) {
          this.$store.dispatch('getAllDecos').then(() => {
            var motifs = this.$store.getters['GET_DECOS']()
            var motifToLoad = motifs.find((motif) => {
              return motif.id === this.currentTheme.idDeco
            })

            if (motifToLoad) {
              this.addBackPattern(motifToLoad)
            } else {
              this.themeLoading = false
            }
          })
        } else {
          this.themeLoading = false
        }
        const centerHorizontalLine = new fabric.Line([0, this.fcanvas.getHeight() / 2, this.fcanvas.getWidth(), this.fcanvas.getHeight() / 2], {
            stroke: 'red',
            strokeWidth: 1,
            selectable: false,
            evented: false,
            visible: this.showGuidelines,
            name: 'Line'
          })
          this.fcanvas.add(centerHorizontalLine)
        const centerVerticalLine = new fabric.Line([this.fcanvas.getWidth() / 2, 0, this.fcanvas.getWidth() / 2, this.fcanvas.getHeight()], {
            stroke: 'red',
            strokeWidth: 1,
            selectable: false,
            evented: false,
            visible: this.showGuidelines,
            name: 'Line'
          })
          this.fcanvas.add(centerVerticalLine)
      })
    },
    showGuidelinesChanged (isShowGuidelines) {
      this.showGuidelines = isShowGuidelines
      this.fcanvas.getObjects().forEach((obj) => {
        if (obj.name === 'Line') {
          obj.visible = this.showGuidelines
        }
      })
      this.fcanvas.renderAll()
    },
    addBackPattern (motif) {
      this.currentTheme.idDeco = motif.id
      fabric.loadSVGFromURL(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + motif.svgPath, (svgpattern) => {
        var ptnGroup = new fabric.Group(svgpattern, {
          top: 0,
          left: 0
        })
        ptnGroup.scaleX = this.fcanvas.width / 4 / ptnGroup.width / window.devicePixelRatio
        ptnGroup.scaleY = this.fcanvas.height / 4 / ptnGroup.width / window.devicePixelRatio
        var colors = []
        ptnGroup.forEachObject((o) => {
          var i = colors.indexOf(o.fill)
          if (i === -1) {
            colors.push(o.fill)
            i = colors.length - 1
          }
          o.groupe = i
        })
        var patternSourceCanvas = new fabric.Canvas()
        patternSourceCanvas.add(ptnGroup)
        patternSourceCanvas.renderAll()
        patternSourceCanvas.setDimensions({
          width: ptnGroup.getScaledWidth(),
          height: ptnGroup.getScaledHeight()
        })
        patternSourceCanvas.renderAll()
        var pattern = new fabric.Pattern({
          source: patternSourceCanvas.getElement(),
          repeat: 'repeat'
        })

        var shape = new fabric.Rect({
          width: this.fcanvas.getWidth(),
          height: this.fcanvas.getHeight(),
          left: -1,
          top: 0,
          name: 'backPattern',
          hasBorders: false,
          selectable: false,
          evented: false,
          hasControls: false,
          fill: pattern,
          objectCaching: false
        })
        shape.svg = ptnGroup
        this.fcanvas.add(shape)
        shape.sendToBack()
        this.fcanvas.renderAll()
        this.themeLoading = false
      })
    },
    outsideClick (event) {
      if (event.target === this.$el) {
        this.fcanvas.discardActiveObject()
        this.fcanvas.renderAll()
      }
    },
    initCanvas () {
      var canvas = document.querySelector('#canvas')
      canvas.width = window.innerWidth / 2.5
      canvas.height = window.innerWidth / 2.5
      this.fcanvas = new fabric.Canvas('canvas')
      this.fcanvas.preserveObjectStacking = true
    },
    select (o) {
      this.objSelected = o.target ? o.target : o
    },
    removeObj (obj) {
      if (obj.isMask) {
        this.fcanvas.getObjects().forEach((object) => {
          if (!object.isMask && object.customClipPathId === obj.customClipPathId) {
            this.fcanvas.remove(object)
          }
        })
      }
      this.fcanvas.remove(obj)
    },
    addObj (obj) {
      switch (obj) {
        case 'line':
          this.addLine()
          break
        case 'square':
          this.addSquare()
          break
        case 'text':
          this.addText()
          break
        case 'circle':
          this.addCircle()
          break
        case 'svg':
          this.importSvgModal = true
          break
        case 'image':
          this.importImageModal = true
          break
      }
    },
    addSquare () {
      var square = new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        absolutePositioned: true,
        originX: 'center',
        originY: 'center',
        fill: '#FFFFFF',
        stroke: '#000000'
      })
      this.fcanvas.add(square)
    },
    addLine () {
      var line = new fabric.Line([200, 300, 400, 300], {
        left: 100,
        top: 100,
        stroke: 'rgb(0,0,0)',
        fill: 'rgb(0,0,0)'
      })
      this.fcanvas.add(line)
    },
    addText () {
      var text = new fabric.IText('Texte', {
        left: 100,
        top: 100,
        strokeWidth: 0,
        originX: 'center',
        originY: 'center'
      })
      this.fcanvas.add(text)
    },
    addCircle () {
      var circle = new fabric.Circle({
        radius: 50,
        originX: 'center',
        originY: 'center',
        left: 100,
        top: 100,
        absolutePositioned: true,
        fill: '#FFFFFF',
        stroke: '#000000'
      })
      this.fcanvas.add(circle)
    },
    renderAll () {
      this.fcanvas.renderAll()
    },
    addSvg () {
      this.importSvgModal = false
      const reader = new FileReader()
      reader.onload = (event) => {
        const data = event.target.result
        fabric.loadSVGFromURL(data, (objects, options) => {
          var imgInstance = fabric.util.groupSVGElements(objects, options)
          imgInstance.scaleX = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
          imgInstance.scaleY = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
          imgInstance.set({
            originX: 'center',
            originY: 'center',
            left: 126,
            top: 126,
            absolutePositioned: true // importance maximale
          })
          if (imgInstance.forEachObject) {
            imgInstance.forEachObject((o) => {
              o.evented = true
              o.onSelect = () => {
              }
            })
          }
          this.fcanvas.add(imgInstance)
        })
      }
      reader.readAsDataURL(this.inputFileSvg)
    },
    addImage () {
      this.importImageModal = false
      const reader = new FileReader()
      reader.onload = () => {
        var newImage = new Image()
        newImage.src = reader.result
        newImage.addEventListener('load', () => {
          var imgInstance = new fabric.Image(newImage, {
            originX: 'center',
            originY: 'center',
            left: 126,
            top: 126
          })

          imgInstance.scaleX = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
          imgInstance.scaleY = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))

          this.fcanvas.add(imgInstance)
        })
      }
      reader.readAsDataURL(this.inputFileImage)
    },
    fixSVGText (str) {
      var svg = new DOMParser().parseFromString(str, 'image/svg+xml').documentElement

      var tspans = svg.querySelectorAll('tspan')
      for (var i = 0; i < tspans.length; i++) {
        var ts = tspans[i]
        var parent = ts.parentNode
        var gParent = parent.parentNode
        var j = 0

        var replace = document.createElementNS('http://www.w3.org/2000/svg', 'text')

        var tsAttr = ts.attributes
        for (j = 0; j < tsAttr.length; j++) {
          replace.setAttribute(tsAttr[j].name, tsAttr[j].value)
        }

        var childNodes = ts.childNodes
        for (j = 0; j < childNodes.length; j++) {
          replace.appendChild(ts.childNodes[j])
        }

        var tAttr = parent.attributes
        for (j = 0; j < tAttr.length; j++) {
          replace.setAttribute(tAttr[j].name, tAttr[j].value)
        }
        gParent.appendChild(replace)

        if (ts === parent.lastElementChild) {
          gParent.removeChild(parent)
        }
      }
      return new XMLSerializer().serializeToString(svg)
    },
    saveCanvas () {
      this.fcanvas.getObjects().forEach((obj) => {
        if (obj.name === 'Line') {
          this.fcanvas.remove(obj)
        }
      })
      this.themeLoading = true
      this.$store.commit('SET_LAST_SELECTED_THEME', this.currentTheme.id)
      if (!this.fcanvas.getObjects().find((obj) => { return obj.name === 'boundingRect' })) {
        var boundingRect = new fabric.Rect({
          height: this.fcanvas.height,
          width: this.fcanvas.width,
          originX: 'center',
          originY: 'center',
          left: this.fcanvas.width / 2,
          top: this.fcanvas.height / 2,
          fill: '',
          name: 'boundingRect',
          selectable: false,
          evented: false
        })
        this.fcanvas.add(boundingRect)
        boundingRect.sendToBack()
      }

      var objects = this.fcanvas.getObjects()

      // for (var j in objects) {
      //   if (!objects[j].clipPath && objects[j] !== boundingRect) objects[j].clipPath = boundingRect
      // }

      var scale = 256 / this.fcanvas.getHeight()
      var width = scale * this.fcanvas.getWidth()
      this.fcanvas.setDimensions({
        width: width,
        height: 256
      })
      this.fcanvas.calcOffset()
      for (var i in objects) {
        var scaleX = objects[i].scaleX
        var scaleY = objects[i].scaleY
        var left = objects[i].left
        var top = objects[i].top

        objects[i].scaleX = scaleX * scale
        objects[i].scaleY = scaleY * scale
        objects[i].left = left * scale
        objects[i].top = top * scale
        objects[i].setCoords()

        this.fcanvas.renderAll()
      }

      objects = this.fcanvas.getObjects()
      objects.forEach((obj) => {
        if (obj.fontFamily) {
          this.$store.dispatch('addSelectedFont', obj.fontFamily)
        }
      })
      var dataPreview = new FormData()
      // var dataSvg = new FormData()
      var svg = this.fixSVGText(this.fcanvas.toSVG())
      var selectedFonts = this.$store.getters['GET_SELECTED_FONTS']()
      var backPattern = this.fcanvas.getObjects().find((o) => {
        return o.name === 'backPattern'
      })
      var base64 = this.fcanvas.toDataURL('image/png')
      if (backPattern) {
        this.fcanvas.remove(backPattern)
      } else {
        this.currentTheme.idDeco = ''
      }
      var json = this.fcanvas.toJSON(['absolutePositioned', 'customClipPathId', 'isMask', 'modifiable', 'name', 'originX'])

      json['fonts'] = []
      if (selectedFonts.length > 0) {
        var svgtest = svg.split('<defs>')
        selectedFonts.filter((selectedFont) => { return this.fonts.find((font) => { return font.name === selectedFont && font.googleFont }) }).forEach((font) => {
          var url = 'https://fonts.googleapis.com/css?family=' + font.replaceAll(' ', '+')
          json['fonts'].push(url)
          svgtest[1] = '@import url(' + url + ');' + svgtest[1]
        })
        svgtest[1] = '<defs><style>' + svgtest[1]
        svgtest = svgtest.join('')
        var svgteststyle = svgtest.split('</defs>')
        svgteststyle[1] = '</style></defs>' + svgteststyle[1]
        svg = svgteststyle.join('')
      }

      var blobPreview = new Blob([this.dataURLtoFile(base64, this.currentTheme.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png')])

      dataPreview.append('fileName', this.currentTheme.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png')
      dataPreview.append('id', this.currentTheme.id)
      dataPreview.append('idDeco', this.currentTheme.idDeco)
      dataPreview.append('json', JSON.stringify(json))
      dataPreview.append('file', blobPreview)

      // var blobSvg = new Blob([svg])
      //
      // dataSvg.append('fileName', this.currentTheme.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.svg')
      // dataSvg.append('id', this.currentTheme.id)
      // dataSvg.append('idDeco', this.currentTheme.idDeco)
      // dataSvg.append('json', JSON.stringify(json))
      // dataSvg.append('file', blobSvg)

      this.$store.dispatch('uploadThemePreview', dataPreview).then(() => {
        // this.$store.dispatch('uploadThemeSvg', dataSvg).then(() => {
        this.$router.push({ name: 'Themes' })
        // })
      })
    },
    storeAllObjects () {
      this.$store.dispatch('storeAllObjects', this.fcanvas.getObjects())
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
  }
}
</script>

<style>
.canvas-container {
  background-color: white;
  position: fixed !important;
  left: 25vw !important;
  top: calc(63vh - 25vw) !important;
}
</style>
