import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import axios from 'axios'
// axios.defaults.baseURL = 'https://4144f1cc2901.ngrok.io'
// axios.defaults.baseURL = 'https://3dback-tony.rag-cloud-bg.hosteur.com'
// axios.defaults.baseURL = 'https://wobz-api.kalessi.net'
axios.defaults.baseURL = 'https://3dback-demo.rag-cloud-bg.hosteur.com'

Vue.prototype.$axios = axios
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#fc6e62',
        secondary: '#60dbc9',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
})
