<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2>Gestion des graduations de produit</h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Measures/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter une graduation
        </v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-center'>Statut de publication</th>
            <th class='text-center'>Nom de la graduation</th>
            <th class='text-center'>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='measure in measures' :key='measure.id'>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyMeasure(measure)"
                    v-model="measure.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ measure.name }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Measures/' + measure.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteMeasure(measure)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Measures',
  data () {
    return {
      measures: []
    }
  },
  created () {
    this.getMeasures()
  },
  methods: {
    deleteMeasure (measure) {
      this.$store.dispatch('deleteMeasure', measure.id).then(() => {
        this.measures = this.$store.getters['GET_MEASURES']()
        this.$emit('notify', {
          color: 'green',
          text: 'Graduation supprimée'
        })
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    getMeasures () {
      this.$store.dispatch('getAllMeasures').then(() => {
        this.measures = this.$store.getters['GET_MEASURES']()
      })
    },
    modifyMeasure (measure) {
      this.$store.dispatch('editMeasure', measure).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
