<template>
  <div class="pa-5">
    <v-card
      class="mx-auto pa-3"
    >
      <v-card-title style="align-items: baseline">
        <h2>Gestion des motifs</h2>
        <v-spacer></v-spacer>
        <v-row>
          <v-text-field :min="1" type="number" v-model="nbShown" style="width: 1%; margin-left: 40%" label="Motifs par page" outlined></v-text-field>
          <v-text-field style="margin-right: 5%; margin-left: 1%" v-model="search" label="Recherche" outlined></v-text-field>
          <v-select
          :items="sections"
          v-model="selectedSection"
          item-text="name"
          item-value="id"
          label="Rubrique"
          outlined
          clearable
          @change="filterDecosBySection"
          class="custom-select"
          ></v-select>
          <v-btn color='primary' @click="$router.push('Decos/new')">
            <v-icon left>
              mdi-plus
            </v-icon>
            Ajouter un motif</v-btn>
        </v-row>
      </v-card-title>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">Aperçu</th>
            <th class='text-center'>Statut de publication</th>
            <th class='text-center'>Nom du motif</th>
            <th class='text-center'>Rubriques
              <v-icon @click="sortDecosBySection('sections', 'asc')">mdi-arrow-up</v-icon>
              <v-icon @click="sortDecosBySection('sections', 'desc')">mdi-arrow-down</v-icon>
            </th>
            <th class='text-center'>Actions</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for='deco in currentDecos' :key='deco.id'>
            <td><img v-if="deco.svgPath !== ''" style="height: 100px; margin: 3px" :src="$axios.defaults.baseURL + '/downloadSvg?filename=' + deco.svgPath"/></td>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyDeco(deco)"
                    v-model="deco.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ deco.name }}</td>
            <td>
              <v-chip label v-if="deco.sectionListNames.length < 2">
                {{ deco.sectionListNames.length > 0 ? deco.sectionListNames[0] : 'Aucune rubrique' }}
              </v-chip>
              <v-menu transition="slide-y-transition" open-on-hover offset-y v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label v-bind="attrs" v-on="on">{{ deco.sectionListNames.length }} rubriques</v-chip>
                </template>
                <div class="category-list">
                  <v-chip style="justify-content: center" class="ma-1" color="#e0e0e0" label v-for="sectionName in deco.sectionListNames" :key="sectionName+deco.id">
                    {{ sectionName }}
                  </v-chip>
                </div>
              </v-menu>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Decos/' + deco.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteDeco(deco)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
    </v-card>
  </div>
</template>

<script>

  export default {
    name: 'Decos',
    data () {
      return {
        decos: [],
        currentPage: 1,
        loading: false,
        search: '',
        currentDecos: [],
        sections: [],
        nbShown: 20,
        selectedSection: ''
      }
    },
    async created () {
      await this.getDecos()
      await this.getSections()
      this.decos.forEach((deco) => {
        deco['sectionListNames'] = []
        this.sections.forEach((section) => {
          if (deco.sectionList.find((sectionId) => { return sectionId === section.id })) {
            deco['sectionListNames'].push(section.name)
          }
        })
      })
      this.selectedSection = ''
      this.currentDecos = this.decos
      this.selectedSection = this.sections[0] ? this.sections[0].id : ''
      this.filterDecosBySection()
    },
    computed: {
      shownDecos () {
        return this.currentDecos.slice((this.currentPage - 1) * this.nbShown, this.currentPage * this.nbShown)
      },
      pages () {
        return Math.floor(this.currentDecos.length / this.nbShown) + (this.currentDecos.length % this.nbShown === 0 ? 0 : 1)
      }
    },
    watch: {
      search: function (val) {
        this.currentPage = 1
        val = val.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        this.currentDecos = val === '' ? this.decos : this.decos.filter((deco) => { return deco.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(val) || deco.sectionListNames.find((section) => { return section.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(val) }) })
      },
      nbShown: function (val) {
        this.nbShown = val <= 0 ? 1 : val
      }
    },
    methods: {
      sortDecosBySection (sortBy, sortDesc) {
        if (sortBy === 'sections') {
          if (sortDesc === 'asc') {
            this.currentDecos.sort((a, b) => {
              return a.sectionListNames[0].localeCompare(b.sectionListNames[0])
            })
          } else {
            this.currentDecos.sort((a, b) => {
              return b.sectionListNames[0].localeCompare(a.sectionListNames[0])
            })
          }
        }
      },
      filterDecosBySection (sectionId) {
        if (!sectionId) {
          this.currentDecos = this.decos
        } else {
          this.currentDecos = this.decos.filter((deco) => {
            return deco.sectionList.includes(sectionId)
          })
        }
        if (this.search !== '') {
          this.currentDecos = this.currentDecos.filter((deco) => {
            return deco.name.toLowerCase().includes(this.search.toLowerCase())
          })
        }
      },
      deleteDeco (deco) {
        this.$store.dispatch('deleteDeco', deco.id).then(() => {
          this.decos = this.$store.getters['GET_DECOS']()
          this.$emit('notify', {
            color: 'green',
            text: 'Motif supprimé'
          })
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      async getDecos () {
        return this.$store.dispatch('getAllDecos').then(() => {
          this.decos = this.$store.getters['GET_DECOS']()
        })
      },
      async getSections () {
        return this.$store.dispatch('getAllSections').then(() => {
          this.sections = this.$store.getters['GET_SECTIONS']()
        })
      },
      modifyDeco (deco) {
        this.$store.dispatch('editDeco', deco).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      }
    }
  }
</script>

<style scoped>
.custom-select .v-select__selections {
  width: 200px
}
</style>
