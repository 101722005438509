export function SET_SITES (state, data) {
  state.sites = data
}
export function SET_CURRENT_SITE (state, data) {
  state.currentSite = data
}
export function SET_SECTIONS (state, data) {
  state.sections = data
}
export function SET_CURRENT_SECTION (state, data) {
  state.currentSection = data
}
export function SET_CATEGORIES (state, data) {
  state.categories = data
}
export function SET_CURRENT_CATEGORY (state, data) {
  state.currentCategory = data
}
export function SET_PRODUCTS (state, data) {
  state.products = data
}
export function SET_CURRENT_PRODUCT (state, data) {
  state.currentProduct = data
}
export function SET_TAGS (state, data) {
    state.tags = data
}
export function SET_CURRENT_TAG (state, data) {
    state.currentTag = data
}

export function SET_DECOS (state, data) {
    state.decos = data
}
export function SET_CURRENT_DECOS (state, data) {
    state.currentDecos = data
}
export function SET_CURRENT_DECO (state, data) {
    state.currentDeco = data
}

export function SET_THEMES (state, data) {
    state.themes = data
}
export function SET_CURRENT_THEME (state, data) {
    state.currentTheme = data
}
export function SET_CURRENT_THEMES (state, data) {
    state.currentThemes = data
}

export function SET_ACCOUNTS (state, data) {
    state.accounts = data
}
export function SET_CURRENT_ACCOUNT (state, data) {
    state.currentAccount = data
}

export function SET_TEXTS (state, data) {
    state.texts = data
}
export function SET_CURRENT_TEXT (state, data) {
    state.currentText = data
}

export function SET_MEASURES (state, data) {
    state.measures = data
}
export function SET_CURRENT_MEASURE (state, data) {
    state.currentMeasure = data
}

export function SET_MATERIALS (state, data) {
    state.materials = data
}
export function SET_CURRENT_MATERIAL (state, data) {
    state.currentMaterial = data
}
export function SET_ALL_OBJECTS (state, data) {
    state.allObjects = data
}

export function SET_SELECTED_FONTS (state, data) {
    state.selectedFonts = data
}

export function SET_LAST_SELECTED_THEME (state, data) {
    state.lastSelectedTheme = data
}

export function SET_IS_AUTHENTICATED (state, data) {
    state.isAuthenticated = data
}

export function SET_ROLES (state, data) {
    state.roles = data
}

export function SET_USERNAME (state, data) {
    state.username = data
}
