<template>
  <div class="pa-5">
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h2>Edition d'un texte</h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' @click="$router.push({ name: 'Texts' })">Retour</v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="currentText.name"
                label="Nom du texte"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="currentText.author"
                label="Auteur du texte"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card outlined>
            <v-card-title>Liste des traductions</v-card-title>
            <v-card-text>
              <v-row class="align-center" v-for="lang in currentText.labelList" :key="lang.langValue">
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-chip>  {{ languageList.find((l) => { return l.value === lang.langValue }).country }}</v-chip>
                </v-col>
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-text-field
                      v-model="lang.label"
                      required
                      outlined
                      clearable
                      :label="'Nom du texte en ' + languageList.find((l) => { return l.value === lang.langValue }).country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentText.published"
            label="Publication"
        ></v-switch>
        <v-btn color='primary' @click='addOrModifyText(false)'>Terminer</v-btn>
        <v-btn style="margin-left: 5px" color='primary' @click='addOrModifyText(true)'>Continuer</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'TextsForm',
    data () {
      return {
        valid: false,
        currentText: {
          id: '',
          name: '',
          labelList: [],
          published: false,
          author: ''
        },
        texts: [],
        languageList: [],
        langLoaded: false
      }
    },
    created () {
      if (this.$route.params.id.indexOf('new') === -1) {
        this.getCurrentText()
      } else {
        this.getSites()
      }
    },
    methods: {
      getSites () {
        this.$store.dispatch('getAllSites').then(() => {
          this.sites = this.$store.getters['GET_SITES']()
          this.languageList = []
          this.sites.forEach((site) => {
            site.languageList.forEach((langToAdd) => {
              if (!this.languageList.find((lang) => {
                return lang.value === langToAdd.value
              })) {
                this.languageList.push(langToAdd)
              }
            })
          })
          this.languageList.forEach((lang) => {
            if (!this.currentText.labelList.find((langLabel) => {
              return langLabel.langValue === lang.value
            })) {
              this.currentText.labelList.push({ langValue: lang.value, label: '' })
            }
          })
          this.langLoaded = true
        })
      },
      getCurrentText () {
        this.$store.dispatch('getText', this.$route.params.id).then(() => {
          this.currentText = this.$store.getters['GET_CURRENT_TEXT']()
          if (this.currentText === null) {
            this.$emit('notify', {
              color: 'red',
              text: 'Texte introuvable'
            })
            this.$router.push({ name: 'Texts' })
          }
          this.getSites()
        })
      },
      addOrModifyText (continuer) {
        this.$refs.form.validate()
        if (this.valid) {
          if (this.currentText.id) {
            this.modifyText(continuer)
          } else {
            this.addText(continuer)
          }
        }
      },
      addText (continuer) {
        this.$store.dispatch('addText', this.currentText).then(() => {
          this.currentText = this.$store.getters['GET_CURRENT_TEXT']()
          continuer ? this.$router.push('/TextCreator/' + this.currentText.id) : this.$router.push('/Texts')
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      modifyText (continuer) {
        this.$store.dispatch('editText', this.currentText).then(() => {
          this.currentText = this.$store.getters['GET_CURRENT_TEXT']()
          continuer ? this.$router.push('/TextCreator/' + this.currentText.id) : this.$router.push('/Texts')
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
