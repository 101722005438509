<template>
  <div>
    <div ref="container" :style="'width: ' +  widthScene + 'px; height: ' + heightScene + 'px;'">
      <v-overlay v-if="sceneLoading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </div>
    <v-navigation-drawer
        right
        :width="'calc(' + widthScene + 'px - 10vw)'"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        class="pa-4"
    >
      <v-row>
        <v-col>
          <v-text-field
              v-model="currentMaterial.name"
              label="Nom du matériau"
              required
              outlined
              clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
              v-model="chosenProduct"
              :items="products"
              item-text="name"
              label="Produit à charger"
              required
              outlined
              return-object
              @change="loadProduct"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
              v-model="chosenMaterial"
              label="Liste des matériaux"
              :items="materialList"
              item-text="type"
              return-object
              outlined
              @change="changeMaterial"
          ></v-select>
        </v-col>
      </v-row>
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <v-checkbox label="Réfraction" v-model="refraction" @click="toggleRefraction"></v-checkbox>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <div v-if="chosenMaterial">
        <div>
          <v-row>
            <v-col>
              <v-select
                  v-model="chosenMaterial.side"
                  :items="sideOptions"
                  item-text="label"
                  label="Side"
                  outlined
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div v-for="attribute in attributeList" :key="attribute+chosenMaterial.type">
          <v-row v-if="chosenMaterial[attribute] !== undefined">
            <v-col>
              <v-slider thumb-label min="0" max="1" step="0.01" v-if="typeof chosenMaterial[attribute] === 'number'" v-model="chosenMaterial[attribute]"
                            :label="attribute" @change="chosenMaterial.needsUpdate = true"></v-slider>
              <v-checkbox v-else-if="typeof chosenMaterial[attribute] === 'boolean'" v-model="chosenMaterial[attribute]" :label="attribute" @change="chosenMaterial.needsUpdate = true"></v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div v-if="chosenMaterial.envMapIntensity">
          <v-file-input @click:append="currentMaterial.envMapPath ? $store.dispatch('downloadFile', currentMaterial.envMapPath) : null" :append-icon="currentMaterial.envMapPath ? 'mdi-download-circle' : null" v-model="fileInputEnvMap" label="envMap" outlined @change="loadEnvMap"></v-file-input>
          <v-slider thumb-label min="0" max="1" step="0.01" v-model="chosenMaterial.envMapIntensity"
                    label="envMapIntensity" @change="chosenMaterial.needsUpdate = true"></v-slider>
        </div>
      </div>
      <v-row>
        <v-col>
          <v-btn color='primary' @click='addOrModifyMaterial'>{{
              $route.params.id.indexOf('new') === -1 ?
                  'Modifier' : 'Ajouter'
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import * as Three from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

var OrbitControls = require('three-orbit-controls')(Three)

export default {
  name: 'DecosForm',
  data () {
    return {
      valid: false,
      currentMaterial: {
        id: '',
        name: '',
        materialJson: {},
        envMapPath: ''
      },
      sideOptions: [{ label: 'FrontSide', value: 0 }, { label: 'BackSide', value: 1 }, { label: 'DoubleSide', value: 2 }],
      chosenMaterial: '',
      chosenProduct: '',
      products: [],
      objectLoaded: {},
      sceneLoading: false,
      scene: {},
      camera: {},
      fileInputEnvMap: null,
      materialList: [
        new Three.MeshBasicMaterial(),
        new Three.MeshDepthMaterial(),
        new Three.MeshNormalMaterial(),
        new Three.MeshLambertMaterial(),
        new Three.MeshMatcapMaterial(),
        new Three.MeshPhongMaterial(),
        new Three.MeshToonMaterial(),
        new Three.MeshStandardMaterial(),
        new Three.MeshPhysicalMaterial()
      ],
      lights: [],
      attributeList: [
        'roughness',
        'metalness',
        'clearcoat',
        'clearcoatRoughness',
        'transmission',
        'vertexColors',
        'vertexTangents',
        'skinning',
        'flatShaded',
        'opacity',
        'transparent',
        'alphaTest',
        'depthTest',
        'depthWrite',
        'wireframe',
        'refractionRatio',
        'reflectivity'
  ],
      cameraConfig: {
        position: {
          x: 0,
          y: 16,
          z: 29
        },
        distance: [30, 50],
        polarAngle: [3 / 12 * Math.PI, 6 / 12 * Math.PI],
        target: {
          x: 0,
          y: 7,
          z: 0
        }
      },
      widthScene: window.innerWidth / 2,
      heightScene: window.innerHeight - 128,
      refraction: false
    }
  },
  created () {
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentMaterial()
    }
    this.getProducts()
  },
  mounted () {
    this.initScene()
  },
  methods: {
    toggleRefraction () {
      var refractCamera = new Three.CubeCamera(0.1, 5000, 512)
      this.scene.add(refractCamera)
      refractCamera.renderTarget.mapping = Three.CubeRefractionMapping
      var refractMaterial = new Three.MeshBasicMaterial({
        color: 0xccccff,
        envMap: refractCamera.renderTarget.texture,
        refractionRatio: 0.985,
        reflectivity: 0.9
      })
      this.objectLoaded.children[3].material = refractMaterial
      refractCamera.position = this.objectLoaded.position
    },
    loadEnvMap (file) {
      if (!this.chosenProduct) {
        return
      }
      var userImageURL
      if (typeof file !== 'string') {
        userImageURL = URL.createObjectURL(file)
      } else {
        userImageURL = file
      }
      var loader = new Three.TextureLoader()
      loader.load(userImageURL, (envMap) => {
        envMap.mapping = Three.EquirectangularReflectionMapping
        this.objectLoaded.children[0].material.needsUpdate = true
        this.objectLoaded.children[0].material.envMap = envMap
        this.objectLoaded.children[1].material.needsUpdate = true
        this.objectLoaded.children[1].material.envMap = envMap
        this.objectLoaded.children[2].material.needsUpdate = true
        this.objectLoaded.children[2].material.envMap = envMap
        this.objectLoaded.children[3].material.needsUpdate = true
        this.objectLoaded.children[3].material.envMap = envMap
      })
    },
    changeMaterial (material) {
      this.chosenMaterial = this.materialList.find((mat) => { return material.type === mat.type })
      material = this.chosenMaterial
      this.currentMaterial.materialJson = material
      if (!this.objectLoaded.children) {
        return
      }
      if (material.color) {
        material.color = new Three.Color(this.chosenProduct.colorList[0].color)
      }
      if (material.emissive) {
        material.emissive = new Three.Color(this.chosenProduct.colorList[0].emissive)
      }
      this.objectLoaded.children[0].material = material
      this.objectLoaded.children[1].material = material
      this.objectLoaded.children[2].material = material
      this.objectLoaded.children[3].material = material
    },
    initScene () {
      this.container = this.$refs.container

      this.camera = new Three.PerspectiveCamera(40, this.container.clientWidth / this.container.clientHeight, 1, 1000)

      this.scene = new Three.Scene()
      this.scene.background = new Three.Color(0x1E1E1E)

      this.renderer = new Three.WebGLRenderer({ antialias: true })
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      this.renderer.physicallyCorrectLights = true
      this.renderer.outputEncoding = Three.sRGBEncoding
      this.renderer.setClearColor(0x000000)
      this.renderer.clearDepth()
      this.container.appendChild(this.renderer.domElement)

      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enablePan = false
      this.controls.update()
      window.onresize = () => {
        this.widthScene = window.innerWidth - 606
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      }
      this.sliderModificationState = null
      this.controls.minDistance = this.cameraConfig.distance[0]
      this.controls.maxDistance = this.cameraConfig.distance[1]
      this.controls.minPolarAngle = parseFloat(this.cameraConfig.polarAngle[0])
      this.controls.maxPolarAngle = parseFloat(this.cameraConfig.polarAngle[1])
      this.cameraConfig.target.x = parseInt(this.cameraConfig.target.x)
      this.cameraConfig.target.y = parseInt(this.cameraConfig.target.y)
      this.cameraConfig.target.z = parseInt(this.cameraConfig.target.z)
      this.controls.target.set(this.cameraConfig.target.x, this.cameraConfig.target.y, this.cameraConfig.target.z)
      this.cameraLoaded = true
      this.animate()
    },
    getCurrentMaterial () {
      this.$store.dispatch('getMaterial', this.$route.params.id).then(() => {
        this.currentMaterial = this.$store.getters['GET_CURRENT_MATERIAL']()
        if (this.currentMaterial === null) {
          this.$emit('notify', {
            color: 'red',
            text: 'Matériau introuvable'
          })
          this.$router.push({ name: 'Materials' })
        } else if (this.currentMaterial.materialJson) {
          var materialLoader = new Three.MaterialLoader()
          this.materialList[this.materialList.indexOf(this.materialList.find((material) => { return this.currentMaterial.materialJson.type === material.type }))] = materialLoader.parse(this.currentMaterial.materialJson)
          this.chosenMaterial = this.materialList.find((material) => { return this.currentMaterial.materialJson.type === material.type })
        }
      })
    },
    getProducts () {
      this.$store.dispatch('getAllProducts', this.$route.params.id).then(() => {
        this.products = this.$store.getters['GET_PRODUCTS']()
        if (this.products === null) {
          this.$emit('notify', {
            color: 'red',
            text: 'Produits introuvables'
          })
          this.$router.push({ name: 'Products' })
        }
      })
    },
    uploadEnvMap () {
      if (this.fileInputEnvMap) {
        this.sceneLoading = true
        var reader = new FileReader()

        reader.onload = () => {
          var data = new FormData()
          var blob = new Blob([reader.result])
          data.append('fileName', this.fileInputEnvMap.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentMaterial.id)
          data.append('file', blob)
          this.$store.dispatch('uploadMaterialEnvMap', data)
        }
        // read the file as text using the reader
        reader.readAsArrayBuffer(this.fileInputEnvMap)
      }
    },
    addOrModifyMaterial () {
      if (this.objectLoaded.children) {
        this.currentMaterial.materialJson = this.objectLoaded.children[2].material
      } else {
        this.currentMaterial.materialJson = this.chosenMaterial
      }
      if (this.currentMaterial.id) {
        this.modifyMaterial()
      } else {
        this.addMaterial()
      }
    },
    addMaterial () {
      this.$store.dispatch('addMaterial', this.currentMaterial).then(() => {
        this.currentMaterial = this.$store.getters['GET_CURRENT_MATERIAL']()
        this.uploadEnvMap()
        this.$router.push({ name: 'Materials' })
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response ? err.response.data : err
        })
      })
    },
    modifyMaterial () {
      this.$store.dispatch('editMaterial', this.currentMaterial).then(() => {
        this.uploadEnvMap()
        this.$router.push({ name: 'Materials' })
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    loadProduct () {
      this.sceneLoading = true
      var objLoader = new OBJLoader()
      this.scene.remove(this.objectLoaded)
      this.$store.dispatch('download', this.chosenProduct.objPath).then((res) => {
        var object3d = objLoader.parse(res)
        object3d.scale.x = 1
        object3d.scale.y = 1
        object3d.scale.z = 1

        // Init des materiaux / séparation en 4 children + les autres childrens userless
        var indexChildToClone = object3d.children.length > 1 ? 1 : 0 // 0 pour a terme
        var tempChild = object3d.children[indexChildToClone]
        var childrenUseless = object3d.children.filter((child, index) => {
          return index !== indexChildToClone
        })
        object3d.children = []
        object3d.add(tempChild.clone()) // product interieur
        object3d.add(tempChild.clone()) // product extérieur
        object3d.add(tempChild.clone()) // texture extérieur
        object3d.add(tempChild.clone()) // texture intérieur
        childrenUseless.forEach((child) => {
          child.material = new Three.MeshPhysicalMaterial({
            color: new Three.Color('rgb(6, 6, 6)'),
            emissive: new Three.Color('rgb(6, 6, 6)'),
            vertexColors: false,
            flatShading: false,
            blending: Three.NormalBlending
          })
          object3d.add(child)
        })
        object3d.children[2].material = new Three.MeshMatcapMaterial({
          color: new Three.Color('rgb(255, 255, 255)'),
          transparent: true,
          side: Three.FrontSide,
          opacity: 1,
          alphaTest: 0.1
        })
        object3d.children[3].material = new Three.MeshMatcapMaterial({
          color: new Three.Color('rgb(255, 255, 255)'),
          transparent: true,
          side: Three.BackSide,
          opacity: 0.2,
          alphaTest: 0.1
        })
        if (object3d.children > 4) { // gourde
          object3d.children[4].material = new Three.MeshPhysicalMaterial({
            color: new Three.Color('rgb(6, 6, 6)'),
            emissive: new Three.Color('rgb(6, 6, 6)'),
            vertexColors: false,
            flatShading: false,
            blending: Three.NormalBlending
          })
        }

        object3d.children[0].scale.x = 0.99
        object3d.children[0].scale.y = 1
        object3d.children[0].scale.z = 0.99
        object3d.children[1].scale.x = 1
        object3d.children[1].scale.y = 1
        object3d.children[1].scale.z = 1
        object3d.children[2].scale.x = 1
        object3d.children[2].scale.y = 1
        object3d.children[2].scale.z = 1
        object3d.children[3].scale.x = 0.98
        object3d.children[3].scale.y = 1
        object3d.children[3].scale.z = 0.98

        this.objectLoaded = object3d
        this.addLights()
        this.scene.add(object3d)
        if (this.chosenMaterial) {
          this.changeMaterial(this.chosenMaterial)
        }
        if (this.currentMaterial.envMapPath && this.chosenMaterial.envMapIntensity) {
          this.loadEnvMap(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + this.currentMaterial.envMapPath)
        }
        this.sceneLoading = false
      })
    },
    addLights () {
      if (this.lights.length) {
        this.lights.forEach((light) => {
          this.scene.remove(light)
        })
        this.lights = []
      }
      if (this.chosenProduct.lightList && this.chosenProduct.lightList.length > 0) {
        var loader = new Three.ObjectLoader()
        this.chosenProduct.lightList.forEach((light) => {
          var lightToAdd = loader.parse(light)
          this.scene.add(lightToAdd)
          this.lights.push(lightToAdd)
        })
      } else {
        this.ambientLight = new Three.AmbientLight(0xFFFFFF, 1)

        this.directionalLight = new Three.DirectionalLight(0xffffff, 0.6)
        this.directionalLight.position.set(10, 15, 10)
        this.directionalLight2 = new Three.DirectionalLight(0xffffff, 0.6)
        this.directionalLight2.position.set(-10, 15, -10)

        this.directionalLight.castShadow = true
        this.scene.add(this.ambientLight)
        this.lights.push(this.ambientLight)
        this.scene.add(this.directionalLight)
        this.lights.push(this.directionalLight)
        this.scene.add(this.directionalLight2)
        this.lights.push(this.directionalLight2)
      }
    },
    animate: function () {
      requestAnimationFrame(this.animate)
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    }
  }
}
</script>

<style scoped>
</style>
