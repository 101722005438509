<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2>Gestion des rubriques</h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Sections/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter une rubrique
        </v-btn>
      </v-card-title>
      <vuetify-draggable-treeview
          v-model="parentSections"
      >
        <template v-slot:prepend="{}">
          <div style="display: flex;">
            <v-icon style="padding: 10px; cursor: move">mdi-cursor-move</v-icon>
          </div>
        </template>
        <template v-slot:append="{ item }">
          <div style="display: flex;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    color="secondary"
                    icon
                    dark
                    @click="$router.push('Sections/' + item.id)"
                    @click.stop=""
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    color="red"
                    icon
                    @click='deleteSection(item)'
                    @click.stop=""
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </div>
        </template>
      </vuetify-draggable-treeview>
    </v-card>
  </div>
</template>

<script>
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'

export default {
  name: 'Sections',
  components: { VuetifyDraggableTreeview },
  data () {
    return {
      valid: false,
      sectionFormVisible: false,
      sections: []
    }
  },
  created () {
    this.getSections()
  },
  computed: {
    parentSections: {
      get () {
      return this.sections.filter((section) => {
        return section.idParent === null
      })
    },
      set (array) {
        this.sections = array
        this.updateIndexes()
      }
    }
  },
  methods: {
    updateIndexes () {
      this.parentSections.forEach((section) => {
        section.index = this.parentSections.indexOf(section)
        this.updateSection(section)
        section.children.forEach((child) => {
          child.index = section.children.indexOf(child)
          this.updateSection(child)
        })
      })
    },
    updateSection (section) {
      this.$store.dispatch('editSection', section)
    },
    deleteSection (section) {
      var removable = true

      if (this.sections.find((sect) => {
        return sect.idParent === section.id
      })) {
        removable = false
      }

      var promises = []

      promises.push(this.$store.dispatch('getAllThemes').then(() => {
        var themes = this.$store.getters['GET_THEMES']()
        if (themes.find((theme) => {
          return theme.sectionList.find((sectionidtheme) => {
            return sectionidtheme === section.id
          })
        })) {
          removable = false
        }
      }))

      promises.push(this.$store.dispatch('getAllDecos').then(() => {
        var decos = this.$store.getters['GET_DECOS']()
        if (decos.find((deco) => {
          return deco.sectionList.find((sectioniddeco) => {
            return sectioniddeco === section.id
          })
        })) {
          removable = false
        }
      }))

      Promise.all(promises).then(() => {
        if (removable) {
          this.$store.dispatch('deleteSection', section.id).then(() => {
            this.sections = this.$store.getters['GET_SECTIONS']()
            this.initSections()
            this.updateIndexes()
            this.$emit('notify', {
              color: 'green',
              text: 'Rubrique supprimée'
            })
          }).catch((err) => {
            this.$emit('notify', {
              color: 'red',
              text: err.response ? err.response.data : err
            })
          })
        } else {
          this.$emit('notify', {
            color: 'red',
            text: 'Vous ne pouvez pas supprimer cette rubrique'
          })
        }
      })
    },
    getSections () {
      this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
        this.initSections()
      })
    },
    initSections () {
      this.sections.sort((a, b) => {
        return b.index < a.index ? 1 : -1
      })
      this.sections.forEach((section) => {
        section.children = this.sections.filter((sec) => {
          return sec.idParent === section.id
        })
      })
    },
    modifySection (section) {
      this.$store.dispatch('editSection', section).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    }
  }
}
</script>

<style>
.theme--light.v-treeview--hoverable .v-treeview-node__root:hover::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover::before {
  opacity: 0.04;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root:focus::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus::before {
  opacity: 0.12;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root--active:hover::before, .theme--light.v-treeview--hoverable .v-treeview-node__root--active::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active::before {
  opacity: 0.12;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root--active:focus::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before, .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0.12;
}
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-treeview .v-treeview-node--disabled > .v-treeview-node__root > .v-treeview-node__content {
  color: rgba(0, 0, 0, 0.38) !important;
}

.theme--dark.v-treeview {
  color: #FFFFFF;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root:hover::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover::before {
  opacity: 0.08;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root:focus::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus::before {
  opacity: 0.24;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:hover::before, .theme--dark.v-treeview--hoverable .v-treeview-node__root--active::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active::before {
  opacity: 0.24;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:focus::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before, .theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0.24;
}
.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-treeview .v-treeview-node--disabled > .v-treeview-node__root > .v-treeview-node__content {
  color: rgba(255, 255, 255, 0.5) !important;
}

.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root,
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root:before {
  border-bottom-right-radius: 24px !important;
  border-top-right-radius: 24px !important;
}
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root,
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 24px !important;
}
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview-node--click > .v-treeview-node__root,
.v-treeview-node--click > .v-treeview-node__root > .v-treeview-node__content > * {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-treeview-node.v-treeview-node--active .v-treeview-node__content .v-icon {
  color: inherit;
}

.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
}
.v-treeview-node__root::before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-treeview-node__root::after {
  content: "";
  font-size: 0;
  min-height: inherit;
}

.v-treeview-node__children {
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.v-treeview--dense .v-treeview-node__root {
  min-height: 40px;
}
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root,
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root:before {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root,
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 20px !important;
}
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-treeview-node__checkbox {
  width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 6px;
}
.v-application--is-rtl .v-treeview-node__checkbox {
  margin-right: 6px;
}

.v-treeview-node__toggle {
  width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-treeview-node__toggle--loading {
  -webkit-animation: progress-circular-rotate 1s linear infinite;
  animation: progress-circular-rotate 1s linear infinite;
}
.v-application--is-ltr .v-treeview-node__toggle {
  transform: rotate(-90deg);
}
.v-application--is-ltr .v-treeview-node__toggle--open {
  transform: none;
}
.v-application--is-rtl .v-treeview-node__toggle {
  transform: rotate(90deg);
}
.v-application--is-rtl .v-treeview-node__toggle--open {
  transform: none;
}

.v-treeview-node__prepend {
  min-width: 24px;
}
.v-application--is-ltr .v-treeview-node__prepend {
  margin-right: 6px;
}
.v-application--is-rtl .v-treeview-node__prepend {
  margin-left: 6px;
}

.v-treeview-node__append {
  min-width: 24px;
}
.v-application--is-ltr .v-treeview-node__append {
  margin-left: 6px;
}
.v-application--is-rtl .v-treeview-node__append {
  margin-right: 6px;
}

.v-treeview-node__level {
  width: 24px;
}

.v-treeview-node__label {
  flex: 1;
  font-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-treeview-node__content {
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
}
.v-treeview-node__content .v-btn {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}
.v-application--is-ltr .v-treeview-node__content {
  margin-left: 6px;
}
.v-application--is-rtl .v-treeview-node__content {
  margin-right: 6px;
}
</style>
