<template>
  <div class="pa-5">
    <v-overlay v-if="uploading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2>Edition d'une rubrique</h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' @click="$router.push({ name: 'Sections' })">Retour</v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form" class="pa-4">
        <v-row>
          <v-col
          >
            <v-text-field
                v-model="currentSection.name"
                label="Nom de la rubrique (par défaut)"
                required
                outlined
                clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="!childrenComputed.length"
          >
            <v-select
                v-model="currentSection.idParent"
                :items="parentsComputed"
                label="Parent"
                item-text="name"
                item-value="id"
                clearable
                outlined
                @click:clear="$nextTick(() => { currentSection.idParent = null })"
            ></v-select>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-card-title>Liste des traductions</v-card-title>
          <v-card-text>
            <v-row class="align-center" v-for="lang in currentSection.labelList" :key="lang.langValue">
              <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                <v-chip> {{
                    languageList.find((l) => {
                      return l.value === lang.langValue
                    }).country
                  }}
                </v-chip>
              </v-col>
              <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                <v-text-field
                    v-model="lang.label"
                    required
                    outlined
                    clearable
                    :label="'Nom de la rubrique en ' + languageList.find((l) => { return l.value === lang.langValue }).country"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
       <v-row>
          <v-col
          >
            <v-file-input @click:append="previewComputed ? $store.dispatch('downloadFile', currentSection.previewPath) : null" :append-icon="previewComputed ? 'mdi-download-circle' : null" ref="fileInput" color="primary" class="ma-5" v-model="inputFilePreview"
                          @change='showPrefixPreview = false' outlined
                          accept="image/*" :clearable="false" label="Ajouter un aperçu de rubrique"
                          :prefix="previewComputed"/>
          </v-col>
        </v-row>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentSection.published"
            label="Publication"
        ></v-switch>
        <v-btn :disabled="!valid" color='primary' @click='addOrModifySection'>{{
            $route.params.id.indexOf('new') === -1 ?
                'Modifier' : 'Ajouter'
          }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SectionsForm',
  data () {
    return {
      langLoaded: false,
      valid: false,
      currentSection: {
        id: '',
        name: '',
        idParent: null,
        labelList: [],
        published: false,
        previewPath: ''
      },
      inputFilePreview: {},
      showPrefixPreview: true,
      uploading: false,
      sections: [],
      languageList: []
    }
  },
  computed: {
    previewComputed () {
      return this.showPrefixPreview ? this.getFileName(this.currentSection.previewPath) : ''
    },
    parentsComputed () {
      return this.sections.filter((sect) => {
        return sect.idParent === null && sect.id !== this.currentSection.id
      })
    },
    childrenComputed () {
      return this.sections.filter((sect) => {
        return sect.idParent === this.currentSection.id
      })
    }
  },
  created () {
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentSection()
    } else {
      this.getSites()
    }
    this.getSections()
  },
  methods: {
    getFileName (name) {
      if (!name) return ''
      name = name.split('_')
      name.shift()
      return name.join('')
    },
    addPreview () {
      if (this.inputFilePreview.name) {
        var reader = new FileReader()

        reader.onload = () => {
          var data = new FormData()
          var blob = new Blob([reader.result])
          data.append('fileName', this.inputFilePreview.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentSection.id)
          data.append('file', blob)
          this.$store.dispatch('uploadSectionPreview', data)
        }
        // read the file as text using the reader
        reader.readAsArrayBuffer(this.inputFilePreview)
      }
    },
    getSections () {
      this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
      })
    },
    getSites () {
      this.$store.dispatch('getAllSites').then(() => {
        this.sites = this.$store.getters['GET_SITES']()
        this.languageList = []
        this.sites.forEach((site) => {
          site.languageList.forEach((langToAdd) => {
            if (!this.languageList.find((lang) => {
              return lang.value === langToAdd.value
            })) {
              this.languageList.push(langToAdd)
            }
          })
        })
        this.languageList.forEach((lang) => {
          if (!this.currentSection.labelList.find((langLabel) => {
            return langLabel.langValue === lang.value
          })) {
            this.currentSection.labelList.push({ langValue: lang.value, label: '' })
          }
        })
      })
    },
    getCurrentSection () {
      this.$store.dispatch('getSection', this.$route.params.id).then(() => {
        this.currentSection = this.$store.getters['GET_CURRENT_SECTION']()
        if (this.currentSection === null) {
          this.$emit('notify', {
            color: 'red',
            text: 'Rubrique introuvable'
          })
          this.$router.push({ name: 'Sections' })
        }
        this.getSites()
        this.getSections()
      })
    },
    addOrModifySection () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentSection.id) {
          this.modifySection()
        } else {
          this.addSection()
        }
      }
    },
    addSection () {
      this.currentSection.index = this.currentSection.idParent ? this.sections.filter((section) => { return section.idParent === this.currentSection.idParent }).length : this.sections.filter((section) => { return !section.idParent }).length
      this.$store.dispatch('addSection', this.currentSection).then(() => {
        this.uploading = true
        this.addPreview()
        setTimeout(() => {
          this.$router.push({ name: 'Sections' })
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    },
    modifySection () {
      this.currentSection.index = this.currentSection.idParent ? this.sections.filter((section) => { return section.idParent === this.currentSection.idParent }).length : this.sections.filter((section) => { return !section.idParent }).length
      this.sections.sort((a, b) => {
        return b.index < a.index ? 1 : -1
      })
      this.$store.dispatch('editSection', this.currentSection).then(() => {
        this.uploading = true
        this.addPreview()
        setTimeout(() => {
          this.$router.push({ name: 'Sections' })
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response.data
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
