<template>
  <div>
    <v-list
        dense
        nav
        class="py-0"
    >
      <v-list-item>
        <v-text-field label="Largeur" type="number" v-model="width" @input="updateObj()" style="margin-right: 35px"></v-text-field>
        <v-text-field label="Hauteur" type="number" v-model="height" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field label="Position en haut" type="number" v-model="top" @input="updateObj()" style="margin-right: 35px"></v-text-field>
        <v-text-field label="Position à gauche" type="number" v-model="left" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field label="Angle" type="number" v-model="angle" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-slider
            v-model="opacity"
            label="Opacité"
            @input="updateObj"
            step="0.01"
            min="0.01"
            max="1"
        >
        </v-slider>
      </v-list-item>
      <div v-if="creator.indexOf('theme') !== -1">
        <v-list-item>
          <v-list-item-content>
            <v-btn @click="setAsMask">{{ maskMode ? 'Désactiver mask' : 'Définir comme mask' }}</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn v-if="maskMode" @click="importMaskedImageModal = true">Importer image à placer</v-btn>
          </v-list-item-content>
        </v-list-item>
      </div>
      <mask-order-controls :fcanvas="fcanvas" :obj="obj"></mask-order-controls>
    </v-list>
    <v-dialog v-model="importMaskedImageModal" max-width="500">
      <v-card>
        <v-card-title class="headline">Importer une image</v-card-title>
        <v-file-input v-model="inputFileImage" accept="image/*" label="Fichier selectionné" style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importMaskedImageModal = false"> Annuler</v-btn>
          <v-btn color="primary' darken-1" text @click="addMaskedImage"> Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import MaskOrderControls from '@/components/drawerRight/MaskOrderControls'

export default {
  props: [
    'obj',
    'refreshSetting',
    'creator',
    'fcanvas'
  ],
  name: 'SettingsSquare',
  components: { MaskOrderControls },
  data () {
    return {
      width: this.obj.width * this.obj.scaleX,
      height: this.obj.height * this.obj.scaleY,
      left: this.obj.left,
      top: this.obj.top,
      angle: this.obj.angle,
      importMaskedImageModal: false,
      inputFileImage: null,
      maskMode: this.obj.isMask,
      opacity: this.obj.opacity
    }
  },
  created () {
    this.obj.on({
      'object:modified': (e) => {
        this.select(e)
      }
    })
  },
  watch: {
    refreshSetting () {
      this.updateLocalObj()
    }
  },
  methods: {
    setAsMask () {
      if (this.obj.isMask) {
        this.fcanvas.getObjects().forEach((object) => {
          if (!object.isMask && object.customClipPathId === this.obj.customClipPathId) {
            this.fcanvas.remove(object)
          }
        })
        this.obj.customClipPathId = ''
        this.obj.isMask = false
        this.maskMode = false
      } else {
        this.obj.customClipPathId = '_' + Math.random().toString(36).substr(2, 9)
        this.obj.isMask = true
        this.maskMode = true
      }
    },
    addMaskedImage () {
      var oldImg = this.fcanvas.getObjects().find((obj) => { return obj.customClipPathId === this.obj.customClipPathId && !obj.isMask })
      this.fcanvas.remove(oldImg)
      this.importMaskedImageModal = false
      const reader = new FileReader()
      reader.onload = () => {
        var newImage = new Image()
        newImage.src = reader.result
        newImage.addEventListener('load', () => {
          var imgInstance = new fabric.Image(newImage, {
            left: this.obj.left,
            top: this.obj.top,
            originX: 'center',
            originY: 'center'
          })
          imgInstance.customClipPathId = this.obj.customClipPathId
          imgInstance.isMask = false
          imgInstance.clipPath = this.obj
          imgInstance.scaleToWidth(100)
          this.fcanvas.add(imgInstance)
          this.fcanvas.renderAll()
        })
      }
      reader.readAsDataURL(this.inputFileImage)
    },
    updateLocalObj () {
      this.width = this.obj.width * this.obj.scaleX
      this.height = this.obj.height * this.obj.scaleY
      this.left = this.obj.left
      this.top = this.obj.top
      this.angle = this.obj.angle
      this.opacity = this.obj.opacity
    },
    updateObj () {
      this.obj.angle = parseFloat(this.angle)
      this.obj.scaleX = this.width / this.obj.width
      this.obj.scaleY = this.height / this.obj.height
      this.obj.left = parseFloat(this.left)
      this.obj.top = parseFloat(this.top)
      this.obj.opacity = this.opacity
      this.updateLocalObj()
      this.$emit('renderAll')
    }
  }
}
</script>

<style scoped>

</style>
