<template>
  <div>
    Cliquez sur un élément du canvas
  </div>
</template>

<script>
export default {
  name: 'SettingsMask'
}
</script>
