<template>
  <div style="background-color: #555; height: 83vh" @mousedown="outsideClick">
    <v-overlay v-if="txtLoading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <canvas id="canvas"/>
    <v-navigation-drawer
      right
      v-model="drawerRight"
      width="350px"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      class="pa-4"
    >
      <drawer-right-text @add="addObj" @remove="removeObj" @renderAll="renderAll" :fonts="fonts" :fcanvas="fcanvas" :objSelected="objSelected"
                    :refreshSetting="refreshSetting" :fontsLoading="fontsLoading"/>
    </v-navigation-drawer>
    <v-dialog v-model="importSvgModal" max-width="500">
      <v-card>
        <v-card-title class="headline">Importer un svg</v-card-title>
        <v-file-input v-model="file" accept="image/svg+xml" label="Fichier selectionné"
                      style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importSvgModal = false"> Annuler</v-btn>
          <v-btn color="primary' darken-1" text @click="addSvg"> Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importImageModal" max-width="500">
      <v-card>
        <v-card-title class="headline">Importer une image</v-card-title>
        <v-file-input v-model="file" accept="image/*" label="Fichier selectionné" style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importImageModal = false"> Annuler</v-btn>
          <v-btn color="primary' darken-1" text @click="addImage"> Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <svg id="SVG-ElementID"></svg>
    <v-footer fixed class="font-weight-medium">
      <v-col class="text-center" cols="12">
        <v-btn @click="saveCanvas">
          Sauvegarder le texte
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
  import { fabric } from 'fabric'
  import DrawerRightText from '../components/drawerRight/DrawerRightText'
  import WebFont from 'webfontloader'

  export default {
    name: 'TextCreator',
    components: {
      DrawerRightText
    },
    data () {
      return {
        objSelected: null,
        fcanvas: null,
        drawerRight: true,
        refreshSetting: 0,
        importSvgModal: false,
        importImageModal: false,
        file: null,
        currentText: {},
        hover: false,
        txtLoading: true,
        fontsLoading: true,
        fontsUsed: [],
        fonts: [{ id: 0, name: 'Arial' },
          { id: 1, name: 'Arial Black' },
          { id: 2, name: 'Bookman Old Style' },
          { id: 3, name: 'Comic Sans MS' },
          { id: 4, name: 'Courier' },
          { id: 5, name: 'Courier New' },
          { id: 6, name: 'Garamond' },
          { id: 7, name: 'Georgia' },
          { id: 8, name: 'Impact' },
          { id: 9, name: 'Lucida Console' },
          { id: 10, name: 'Lucida Sans Unicode' },
          { id: 11, name: 'MS Sans Serif' },
          { id: 12, name: 'MS Serif' },
          { id: 13, name: 'Palatino Linotype' },
          { id: 14, name: 'Symbol' },
          { id: 15, name: 'Tahoma' },
          { id: 16, name: 'Times New Roman' },
          { id: 17, name: 'Trebuchet MS' },
          { id: 18, name: 'Verdana' }]
      }
    },
    mounted () {
      this.$store.dispatch('clearSelectedFonts')
      this.initCanvas()
      this.fcanvas.on({
        'selection:created': (e) => {
          if (!this.hover) {
            this.select(e)
          }
        },
        'selection:cleared': () => {
          this.objSelected = null
        },
        'selection:updated': (e) => {
          if (!this.hover) {
            this.objSelected = null
            this.select(e)
          }
        },
        'object:modified': () => {
          this.refreshSetting += 1
        }
      })
      this.$store.dispatch('getText', this.$route.params.id).then(() => {
        this.currentText = this.$store.getters['GET_CURRENT_TEXT']()
        if (!this.currentText) {
          this.$router.push({ name: 'Texts' })
        }
        var parsedJson = JSON.parse(this.currentText.textJson)
        if (parsedJson) {
          if (parsedJson.fonts.length > 0) {
            parsedJson.fonts.forEach((font) => {
              this.fontsUsed.push({ id: this.fontsUsed.length, name: font.split('family=')[1], googleFont: true })
            })
            WebFont.load({
              google: {
                families: this.fontsUsed.map(font => font.name)
              },
              active: () => {
                this.loadTxtJSON()
              }
            })
          } else {
            this.loadTxtJSON()
          }
        } else {
          this.txtLoading = false
        }
        this.$axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCI3shZ9mFn46o-uPPI_3p-3H9bb-si4Vo').then((res) => {
          res.data.items.forEach((i) => {
            this.fonts.push({ id: this.fonts.length, name: i.family, googleFont: true })
          })
          this.fonts.sort((a, b) => {
            return b.name < a.name ? 1 : -1
          })
          if (!this.$store.state.areFontsLoaded) {
            WebFont.load({
              google: {
                families: this.fonts.filter((font) => { return font.googleFont }).map(font => font.name)
              },
              active: () => {
                this.fontsLoading = false
                this.$store.state.areFontsLoaded = true
              }
            })
          } else {
            this.fontsLoading = false
          }
        })
      })
    },
    methods: {
      outsideClick (event) {
        if (event.target === this.$el) {
          this.fcanvas.discardActiveObject()
          this.fcanvas.renderAll()
        }
      },
      loadTxtJSON () {
        this.fcanvas.loadFromJSON(this.currentText.textJson, () => {
          fabric.util.clearFabricFontCache()

          var scale = this.fcanvas.getHeight() / 256
          var width = scale * 256
          this.fcanvas.setDimensions({
            width: width,
            height: width
          })
          this.fcanvas.calcOffset()
          var objects = this.fcanvas.getObjects()
          for (var i in objects) {
            var scaleX = objects[i].scaleX
            var scaleY = objects[i].scaleY
            var left = objects[i].left
            var top = objects[i].top

            objects[i].scaleX = scaleX * scale
            objects[i].scaleY = scaleY * scale
            objects[i].left = left * scale
            objects[i].top = top * scale
            objects[i].setCoords()

            this.fcanvas.renderAll()
          }
          this.txtLoading = false
        })
      },
      initCanvas () {
        var canvas = document.querySelector('#canvas')
        canvas.width = window.innerWidth / 2.5
        canvas.height = window.innerWidth / 2.5
        this.fcanvas = new fabric.Canvas('canvas')
      },
      select (o) {
        this.objSelected = o.target ? o.target : o
      },
      addSvg () {
        this.importSvgModal = false
        const reader = new FileReader()
        // var mask = null
        // var scale = 0.5
        reader.onload = (event) => {
          const data = event.target.result
          fabric.loadSVGFromURL(data, (objects, options) => {
            var imgInstance = fabric.util.groupSVGElements(objects, options)
            imgInstance.scaleX = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
            imgInstance.scaleY = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
            // imgInstance.forEachObject((obj) => {
            //   if (obj.id === 'marron') {
            //     mask = obj
            //     mask.absolutePositioned = true
            //   }
            // })
            // imgInstance.type = 'svg'
            imgInstance.set({
              originX: 'center',
              originY: 'center',
              left: 126,
              top: 126
            })
            imgInstance.forEachObject((o) => {
              o.evented = true
              o.onSelect = () => {
              }
            })
            this.fcanvas.add(imgInstance)
          })
        }
        reader.readAsDataURL(this.file)
      },
      addImage () {
        this.importImageModal = false
        const reader = new FileReader()
        reader.onload = () => {
          var newImage = new Image()
          newImage.src = reader.result
          newImage.addEventListener('load', () => {
            var imgInstance = new fabric.Image(newImage, {
              originX: 'center',
              originY: 'center',
              left: 126,
              top: 126
            })

            imgInstance.scaleX = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
            imgInstance.scaleY = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))

            this.fcanvas.add(imgInstance)
          })
        }
        reader.readAsDataURL(this.file)
      },
      addObj (obj) {
        switch (obj) {
          case 'line':
            this.addLine()
            break
          case 'square':
            this.addSquare()
            break
          case 'text':
            this.addText()
            break
          case 'svg':
            this.importSvgModal = true
            break
          case 'image':
            this.importImageModal = true
            break
        }
      },
      removeObj (obj) {
        this.fcanvas.remove(obj)
      },
      renderAll () {
        this.fcanvas.renderAll()
      },
      addSquare () {
        var square = new fabric.Rect({
          width: 100,
          height: 100,
          originX: 'center',
          originY: 'center',
          left: 51,
          top: 51,
          fill: '#fff',
          stroke: '#000',
          strokeWidth: 1
        })
        this.fcanvas.add(square)
      },
      addLine () {
        var line = new fabric.Line([200, 300, 400, 300], {
          left: 100,
          top: 100,
          stroke: 'rgb(0,0,0)',
          fill: 'rgb(0,0,0)'
        })
        this.fcanvas.add(line)
      },
      addText () {
        var text = new fabric.IText('Texte', {
          left: 100,
          top: 100,
          strokeWidth: 0,
          originX: 'center',
          originY: 'center'
        })
        this.fcanvas.add(text)
      },
      fixSVGText (str) {
        var svg = new DOMParser().parseFromString(str, 'image/svg+xml').documentElement

        var tspans = svg.querySelectorAll('tspan')
        for (var i = 0; i < tspans.length; i++) {
          var ts = tspans[i]
          var parent = ts.parentNode
          var gParent = parent.parentNode
          var j = 0

          var replace = document.createElementNS('http://www.w3.org/2000/svg', 'text')

          var tsAttr = ts.attributes
          for (j = 0; j < tsAttr.length; j++) {
            replace.setAttribute(tsAttr[j].name, tsAttr[j].value)
          }

          var childNodes = ts.childNodes
          for (j = 0; j < childNodes.length; j++) {
            replace.appendChild(ts.childNodes[j])
          }

          var tAttr = parent.attributes
          for (j = 0; j < tAttr.length; j++) {
            replace.setAttribute(tAttr[j].name, tAttr[j].value)
          }
          gParent.appendChild(replace)

          if (ts === parent.lastElementChild) {
            gParent.removeChild(parent)
          }
        }
        return new XMLSerializer().serializeToString(svg)
      },
      saveCanvas () {
        this.txtLoading = true
        var scale = 256 / this.fcanvas.getHeight()
        var width = scale * this.fcanvas.getWidth()
        this.fcanvas.setDimensions({
          width: width,
          height: 256
        })
        this.fcanvas.calcOffset()
        var objects = this.fcanvas.getObjects()
        for (var i in objects) {
          var scaleX = objects[i].scaleX
          var scaleY = objects[i].scaleY
          var left = objects[i].left
          var top = objects[i].top

          objects[i].scaleX = scaleX * scale
          objects[i].scaleY = scaleY * scale
          objects[i].left = left * scale
          objects[i].top = top * scale
          objects[i].setCoords()

          this.fcanvas.renderAll()
        }

        objects = this.fcanvas.getObjects()
        objects.forEach((obj) => {
          if (obj.fontFamily) {
            this.$store.dispatch('addSelectedFont', obj.fontFamily)
          }
        })
        var data = new FormData()
        var svg = this.fixSVGText(this.fcanvas.toSVG())
        var selectedFonts = this.$store.getters['GET_SELECTED_FONTS']()
        var json = this.fcanvas.toJSON()

        json['fonts'] = []
        if (selectedFonts.length > 0) {
          var svgtest = svg.split('<defs>')
          selectedFonts.forEach((font) => {
            var url = 'https://fonts.googleapis.com/css?family=' + font.replaceAll(' ', '+')
            json['fonts'].push(url)
            svgtest[1] = '@import url(' + url + ');' + svgtest[1]
          })
          svgtest[1] = '<defs><style>' + svgtest[1]
          svgtest = svgtest.join('')
          var svgteststyle = svgtest.split('</defs>')
          svgteststyle[1] = '</style></defs>' + svgteststyle[1]
          svg = svgteststyle.join('')
        }

        var blob = new Blob([svg])

        data.append('fileName', this.currentText.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.svg')
        data.append('id', this.currentText.id)
        data.append('json', JSON.stringify(json))
        data.append('file', blob)
        this.$store.dispatch('uploadTextSvg', data).then(() => {
          this.$router.push({ name: 'Texts' })
        })
      },
      storeAllObjects () {
        this.$store.dispatch('storeAllObjects', this.fcanvas.getObjects())
      }
    }
  }
</script>

<style>
  .canvas-container {
    background-color: white;
    position: fixed !important;
    left: 25vw !important;
    top: calc(60vh - 25vw) !important;
  }
</style>
