<template>
  <div class="pa-5">
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h2>Gestion des catégories de produit</h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Categories/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter une catégorie</v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-center'>Statut de publication</th>
            <th class='text-center'>Nom de la catégorie</th>
            <th class='text-center'>Actions</th>
            <th class='text-center'>Tri</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='category in categories' :key='category.id'>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyCategory(category)"
                    v-model="category.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ category.name }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Categories/' + category.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteCategory(category)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </td>
            <td>
              <div style="display: inline-flex; flex-direction: column">
                <v-tooltip top v-if="categories.indexOf(category) !== 0">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="red"
                        icon
                        @click='increasePrio(category)'
                    >
                      <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Monter</span>
                </v-tooltip>
                <v-tooltip bottom v-if="categories.indexOf(category) !== categories.length - 1">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="red"
                        icon
                        @click='decreasePrio(category)'
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Descendre</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Categories',
    data () {
      return {
        categories: [],
        products: []
      }
    },
    created () {
      this.getCategories()
    },
    methods: {
      increasePrio (category) {
        var oldIndex = category.index
        category.index = this.categories[this.categories.indexOf(category) - 1].index
        this.categories[this.categories.indexOf(category) - 1].index = oldIndex
        this.updateCategory(category)
        this.updateCategory(this.categories[this.categories.indexOf(category) - 1])
        this.sortCategories()
      },
      decreasePrio (category) {
        var oldIndex = category.index
        category.index = this.categories[this.categories.indexOf(category) + 1].index
        this.categories[this.categories.indexOf(category) + 1].index = oldIndex
        this.updateCategory(category)
        this.updateCategory(this.categories[this.categories.indexOf(category) + 1])
        this.sortCategories()
      },
      sortCategories () {
        this.categories.sort((a, b) => {
          if (a.index === null) {
            return 1
          }
          if (b.index === null) {
            return -1
          }
          return a.index - b.index
        })
        this.categories.forEach((p, index) => {
          p.index = index
        })
      },
      updateCategory (category) {
        this.$store.dispatch('editCategory', category)
      },
      deleteCategory (category) {
        this.$store.dispatch('getAllProducts').then(() => {
          var removable = true
          this.products = this.$store.getters['GET_PRODUCTS']()
          this.products.forEach((product) => {
            if (product.categoryId === category.id) {
              this.$emit('notify', {
                color: 'red',
                text: 'Vous ne pouvez pas supprimer cette catégorie'
              })
              removable = false
            }
          })
          if (removable) {
            this.$store.dispatch('deleteCategory', category.id).then(() => {
              this.categories = this.$store.getters['GET_CATEGORIES']()
              this.$emit('notify', {
                color: 'green',
                text: 'Catégorie supprimée'
              })
            }).catch((err) => {
              this.$emit('notify', {
                color: 'red',
                text: err.response.data
              })
            })
          }
        })
      },
      getCategories () {
        this.$store.dispatch('getAllCategories').then(() => {
          this.categories = this.$store.getters['GET_CATEGORIES']()
          this.sortCategories()
        })
      },
      modifyCategory (category) {
        this.$store.dispatch('editCategory', category).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
