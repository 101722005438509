<template>
  <div class="pa-5">
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card class="mx-auto" width="30%" min-width="250px">
      <v-card-title>
        Connexion
      </v-card-title>
      <v-form class="pa-4">
        <v-row>
          <v-col>
            <v-text-field v-model="username" outlined label="Nom d'utilisateur"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="password" type="password" outlined label="Mot de passe"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="login">Valider</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import EventBus from '../bus.js'

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      loading: false
    }
  },
  created () {
    EventBus.$emit('hideLeftNav')
  },
  methods: {
    login () {
      this.loading = true
      this.$store.dispatch('login', { username: this.username, password: this.password }).then(() => {
        this.$router.push({ name: 'Sites' })
        this.$emit('notify', {
          color: 'green',
          text: 'Connexion réussie'
        })
        EventBus.$emit('showLeftNav')
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: err.response ? err.response.data : err
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
