<template>
  <v-list-item>
    <v-col>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              icon
              @click="fcanvas.bringToFront(obj)"
          >
            <v-icon>mdi-arrange-bring-to-front</v-icon>
          </v-btn>
        </template>
        <span>Premier plan</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              icon
              @click="fcanvas.bringForward(obj)"
          >
            <v-icon>mdi-arrange-bring-forward</v-icon>
          </v-btn>
        </template>
        <span>Avancer</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              icon
              @click="fcanvas.sendBackwards(obj)"
          >
            <v-icon>mdi-arrange-send-backward</v-icon>
          </v-btn>
        </template>
        <span>Reculer</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              icon
              @click="fcanvas.sendToBack(obj)"
          >
            <v-icon>mdi-arrange-send-to-back</v-icon>
          </v-btn>
        </template>
        <span>Arrière plan</span>
      </v-tooltip>
    </v-col>
  </v-list-item>
</template>

<script>
export default {
  name: 'MaskOrderControls',
  props: [
      'obj',
      'fcanvas'
  ],
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
