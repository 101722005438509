<template>
  <div>
    <v-list
        dense
        nav
        class="py-0"
    >
      <v-list-item>
        <v-text-field label="Largeur" type="number" v-model="width" @input="updateObj()" style="margin-right: 35px"></v-text-field>
        <v-text-field label="Hauteur" type="number" v-model="height" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field label="Position en haut" type="number" v-model="top" @input="updateObj()" style="margin-right: 35px"></v-text-field>
        <v-text-field label="Position à gauche" type="number" v-model="left" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field label="Angle" type="number" v-model="angle" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-slider
            v-model="opacity"
            label="Opacité"
            @input="updateObj"
            step="0.01"
            min="0.01"
            max="1"
        >
        </v-slider>
      </v-list-item>
      <v-list-item v-if="creator.indexOf('theme') !== -1">
        <v-checkbox v-model="obj.modifiable" label="Modifiable"></v-checkbox>
      </v-list-item>
      <mask-order-controls :fcanvas="fcanvas" :obj="obj"></mask-order-controls>
    </v-list>
  </div>
</template>

<script>
import MaskOrderControls from '@/components/drawerRight/MaskOrderControls'

export default {
  props: [
    'obj',
    'refreshSetting',
    'fcanvas',
    'creator'
  ],
  name: 'SettingsSquare',
  components: { MaskOrderControls },
  data () {
    return {
      width: this.obj.width * this.obj.scaleX,
      height: this.obj.height * this.obj.scaleY,
      left: this.obj.left,
      top: this.obj.top,
      angle: this.obj.angle,
      opacity: this.obj.opacity
    }
  },
  created () {
    this.obj.on({
      'object:modified': (e) => {
        this.select(e)
      }
    })
  },
  watch: {
    refreshSetting () {
      this.updateLocalObj()
    }
  },
  methods: {
    updateLocalObj () {
      this.width = this.obj.width * this.obj.scaleX
      this.height = this.obj.height * this.obj.scaleY
      this.left = this.obj.left
      this.top = this.obj.top
      this.angle = this.obj.angle
      this.opacity = this.obj.opacity
    },
    updateObj () {
      this.obj.angle = parseFloat(this.angle)
      this.obj.scaleX = this.width / this.obj.width
      this.obj.scaleY = this.height / this.obj.height
      this.obj.left = parseFloat(this.left)
      this.obj.top = parseFloat(this.top)
      this.obj.opacity = this.opacity
      this.updateLocalObj()
      this.$emit('renderAll')
    }
  }
}
</script>

<style scoped>

</style>
