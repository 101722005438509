import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      { path: '/Sites', component: () => import('../views/Sites/Sites'), name: 'Sites', meta: { requiresAuth: true } },
      { path: '/Sites/:id', component: () => import('../views/Sites/SitesForm'), name: 'SitesForm', meta: { requiresAuth: true } },
      { path: '/Sections', component: () => import('../views/Sections/Sections'), name: 'Sections', meta: { requiresAuth: true } },
      { path: '/Sections/:id', component: () => import('../views/Sections/SectionsForm'), name: 'SectionsForm', meta: { requiresAuth: true } },
      { path: '/Categories', component: () => import('../views/Categories/Categories'), name: 'Categories', meta: { requiresAuth: true } },
      { path: '/Categories/:id', component: () => import('../views/Categories/CategoriesForm'), name: 'CategoriesForm', meta: { requiresAuth: true } },
      { path: '/Decos', component: () => import('../views/Decos/Decos'), name: 'Decos', meta: { requiresAuth: true } },
      { path: '/Decos/:id', component: () => import('../views/Decos/DecosForm'), name: 'DecosForm', meta: { requiresAuth: true } },
      { path: '/Themes', component: () => import('../views/Themes/Themes'), name: 'Themes', meta: { requiresAuth: true } },
      { path: '/Themes/:id', component: () => import('../views/Themes/ThemesForm'), name: 'ThemesForm', meta: { requiresAuth: true } },
      { path: '/Measures', component: () => import('../views/Measures/Measures'), name: 'Measures', meta: { requiresAuth: true } },
      { path: '/Measures/:id', component: () => import('../views/Measures/MeasuresForm'), name: 'MeasuresForm', meta: { requiresAuth: true } },
      { path: '/Texts', component: () => import('../views/Texts/Texts'), name: 'Texts', meta: { requiresAuth: true } },
      { path: '/Texts/:id', component: () => import('../views/Texts/TextsForm'), name: 'TextsForm', meta: { requiresAuth: true } },
      { path: '/Products', component: () => import('../views/Products/Products'), name: 'Products', meta: { requiresAuth: true } },
      { path: '/Products/:id', component: () => import('../views/Products/ProductsForm'), name: 'ProductsForm', meta: { requiresAuth: true } },
      { path: '/ThreeScene/:id', component: () => import('../views/ThreeScene'), name: 'ThreeScene', meta: { requiresAuth: true } },
      { path: '/TextCreator/:id', component: () => import('../views/TextCreator'), name: 'TextCreator', meta: { requiresAuth: true } },
      { path: '/ThemeCreator/:id', component: () => import('../views/ThemeCreator'), name: 'ThemeCreator', meta: { requiresAuth: true } },
      { path: '/Materials', component: () => import('../views/Materials/Materials'), name: 'Materials', meta: { requiresAuth: true } },
      { path: '/Materials/:id', component: () => import('../views/Materials/MaterialsForm'), name: 'MaterialsForm', meta: { requiresAuth: true } },
      { path: '/Accounts', component: () => import('../views/Accounts/Accounts'), name: 'Accounts', meta: { requiresAuth: true } },
      { path: '/Accounts/:id', component: () => import('../views/Accounts/AccountsForm'), name: 'AccountsForm', meta: { requiresAuth: true } },
      { path: '/Login', component: () => import('../views/Login'), name: 'Login', meta: { guest: true } }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  Store.dispatch('getAuthenticatedUser').then(() => {
    if (!Store.getters['GET_IS_AUTHENTICATED']()) {
      if (to.name !== 'Login') {
        next({ name: 'Login' })
      } else {
        next()
      }
    } else {
      if (to.name === 'Login') {
        next({ name: 'Sites' })
      } else {
        if (to.name.indexOf('Accounts') !== -1 && !Store.getters['GET_ROLES']().find((role) => { return role === 'ROLE_ADMIN' })) {
          next({ name: 'Sites' })
        } else {
          next()
        }
      }
    }
  })
})

export default router
