<template>
  <div>
    <v-list
        dense
        nav
        class="py-0"
    >
      <v-list-item @click="$parent.elementActive = null">
          <v-list-item-content>
              <v-btn>Retour</v-btn>
          </v-list-item-content>
      </v-list-item>
      <v-list-item
          link
          @click="emit(compo.value)"
          v-for="compo in listeCompo"
          :key="compo.value"
      >
        <v-icon>{{compo.icon}}</v-icon>
        <v-list-item-content>
          <v-list-item-title>{{compo.label}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'DrawerRightAdd',
  data () {
    return {
      listeCompo: [
        { label: 'Carré', icon: 'mdi-square', value: 'square' },
        { label: 'Texte', icon: 'mdi-card-text', value: 'text' },
        { label: 'Ligne', icon: 'mdi-minus', value: 'line' },
        { label: 'Cercle', icon: 'mdi-circle-outline', value: 'circle' },
        { label: 'SVG', icon: 'mdi-svg', value: 'svg' },
        { label: 'Image', icon: 'mdi-image', value: 'image' }
      ]
  }
  },
  methods: {
    emit (obj) {
      this.$emit('add', obj)
    }
  }
}
</script>

<style scoped>

</style>
